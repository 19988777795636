.department-wrapper {
  padding: 40px 30px 0 29px;

  .title {
    font-weight: 700;
    font-size: 22px;
    line-height: 135%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #283445;
  }

  .department-info {
    padding: 15px 30px;
    background: #FFFFFF;
    border: 1px solid #DEE6F5;
    box-sizing: border-box;
    border-radius: 8px;

    &__field-wrapper {
      border-bottom: 1px solid #DEE6F5;
      padding: 10px 0;
    }

    &__field-wrapper:last-child {
      border-bottom: none;
    }
  }

  .line {
    margin-top: 9px !important;
    border: none;
    background: #DEE6F5;
    height: 1px;
  }

  .name {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #283445;
  }

  .value {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #283445;
  }
}

.car-damage {
  --damage-image-width: 500px;
  --damage-image-height: 500px;

  .car-damage-photos-wrapper {
    display: flex;
    justify-content: center;
  }

  .gallery__overlay {
    z-index: 1100;
  }

  &__modal.modal-dialog {
    max-width: 600px;
  }

  &__slider {
    margin: 0 auto;
  }

  &__wrapper {
    display: flex;
    border-bottom: 1px solid #DEE6F5;
    padding: 20px 15px;
    min-height: 86px;
    box-sizing: border-box;
  }

  &__wrapper:last-child {
    border: none;
    margin-bottom: 0;
  }

  &__wrapper-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
  }

  &__wrapper-info-elements {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #DEE6F5;
    padding: 20px 0;
  }

  &__wrapper-info-elements:first-child {
    padding-top: 0;
  }

  &__wrapper-info-elements:last-child {
    border: none;
    padding-bottom: 0;
  }

  &__wrapper-info-elements-defect {
    display: flex;
    align-items: start;
    width: 100%;
    height: 100%;

    &-name {
      width: 30%;
      text-align: center;
      margin-right: 20px;
      margin-left: 60px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &__wrapper-info-element-name, &__wrapper-info-element-title-name {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #283445;
  }

  &__wrapper-info-element-name {
    margin-left: 60px;
    width: 30%;
  }

  &__image-wrap {
    position: relative;
  }

  &__image-wrap {
    width: var(--damage-image-width);
    height: var(--damage-image-height);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .car-damage-photos {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }

  &__image-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    text-align: center;
    color: #283445;
    margin-bottom: 22px;
  }

  &__points {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &__point {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    background-color: #C81A41;
    opacity: 0.5;
    cursor: pointer;
    user-select: none;

    &:hover {
      opacity: 0.7;
    }
  }

  &__image-defect {
    width: 60px;
    height: 40px;
    border-radius: 6px;
  }

  &__img-wrap {
    cursor: pointer;
    position: relative;

    &:hover:after {
      content: "";
      background-color: rgba(40, 52, 69, 72%);
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAOVBMVEUAAAD///////////////////////////////////////////////////////////////////////8KOjVvAAAAEnRSTlMAECAwQF9gb3B/gI+Qr7/P3+8vV0R8AAAAlElEQVR42r2Q2w6EMAhEKa332zD//7FrKYnd7D57Xjw6EzDIi6TpAIlzUPliMgaY+/pJnqOK5LGaSqAgigQjCA2/XLvaldy2+L6YbZH4s5Da8pu1WjbWyWhvYlSlua7E/2DxoNByq93svoQ+SvZYvputsdxzSb+/+ygGCQq6mtZDDPkWP0mShxkMbJYO74LEMSV5jw+W1AkJM6PXXAAAAABJRU5ErkJggg==);
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }

  @media screen and (max-width: 780px) {
    .car-damage-photos-wrapper {
      justify-content: start;

      .car-damage-photos {
        flex-direction: column;
      }
    }

    &__wrapper {
      min-height: 0;

      .arrow-down {
        width: 24px;
      }
    }

    .title {
      font-size: 14px;
      font-weight: 700;
    }

    .name {
      font-size: 14px;
      font-weight: 700;
    }

    &__wrapper-info {
      width: auto;
      margin-left: 40px;
    }

    &__wrapper-info-elements {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    &__wrapper-info-elements:first-child {
      margin-top: 10px;
    }

    &__wrapper-info-elements-defect {
      width: auto;
      flex-direction: column;

      &-name {
        margin: 0 0 5px 0;
        width: auto;
      }
    }

    &__wrapper-info-element-name {
      margin: 0 0 5px 0;
      width: auto !important;
    }
  }
}

@media screen and (min-width: 780px) and (max-width: 1200px) {
  .car-damage-photos {
    flex-direction: column;
  }
}

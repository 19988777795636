.edit-trades-result-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  .modal-dialog {
    max-width: 1016px;
  }

  .modal-content {
    background-color: #F4F5FB !important;
    padding-bottom: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }

  &__header-text {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }

  &__header-text-step {
    font-size: 12px;
    font-weight: 600;
    color: #656C7B;
  }

  &__car-name {
    color: #656C7B;
    font-size: 12px;
    font-weight: 500;
  }

  &__title {
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
  }


  .table-class-wrap {
    max-height: 400px;
    overflow-y: auto;
  }

  .pagination-modal-wrapper {
    background-color: #FFFFFF;
    margin: -6px -35px 0 -35px;
    border-top: 1px solid #DEE6F5;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    .pagination-wrapper {
      justify-content: start;
      padding: 24px;
      margin-top: 0;
      box-shadow: 0px -8px 10px 0px rgba(40, 52, 69, 0.08);

      .result {
        color: #656C7B;
      }
    }
  }

  th {
    height: 40px;
    cursor: auto;
  }

  td {
    height: 44px;
    cursor: pointer;
  }

  .user-status {
    padding: 6px;
    border-radius: 40px;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    margin: 0 20px;
  }

  .green {
    background: rgba(106, 201, 110, 0.1);
    color: #339337;
  }

  .grey {
    background-color: #EDF1FA;
    color: #656C7B;
  }

  .red {
    background: rgba(240, 68, 97, 0.1);
    color: #F04461;
  }

  .user-filters, .companies-filters {
    padding: 20px;
    border: 1px solid var(--main-color-light-blue);
    background-color: #FFF;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 20px;
    margin-top: 25px;
  }

  .user-filters {
    .input, .react-select {
      width: 218px;
    }
  }

  .companies-filters {
    .input {
      max-width: 294px;
      width: 100%;
    }
  }

  .edit-winning-bet-wrapper {
    display: grid;
    margin-top: 24px;
    padding: 15px 30px;
    background: #FFFFFF;
    border: 1px solid var(--main-color-light-blue);
    box-sizing: border-box;
    border-radius: 8px;
    gap: 16px;

    &__field {
      display: grid;
      grid-template-columns: 34% 66%;
      align-items: center;
    }

    &__field-name {
      font-weight: 700;
      line-height: 22px;
    }

    &__field-link {
      display: flex;
      align-items: center;
      gap: 2px;
      color: #2D78E9;
      cursor: pointer;
      font-size: 14px;
      font-weight: 700;
    }

    .input {
      width: 196px;
    }
  }

  .edit-winning-bet-btns {
    margin: 24px 0;
    padding-top: 17px;
    display: flex;
    gap: 12px;
    justify-content: end;
    border-top: 1px solid #DEE6F5;
  }
}

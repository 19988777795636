.input {
  padding: 0 16px;
  color: #283445;
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  background: #F4F6FB;
  border: 1px solid rgba(156, 163, 176, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  font-weight: 500;

  &_l {
    height: 44px;
  }

  &_m {
    height: 40px;
  }

  &_s {
    height: 36px;
  }

  &_xs {
    height: 32px;
    font-size: 14px;
  }

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }

  &::placeholder {
    color: #9CA3B0;
  }

  &_white {
    background-color: #FFF;
  }
}

.input:hover {
  border: 1px solid #8A92A1;
}

.users-page-wrapper {
  padding: 20px;

  .tabs {
    .tab-btn {
      margin-left: 10px;
      outline: none;
      padding: 10px 30px;
      border-radius: 60px;
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #283445;
      background: white;
      border: 1px solid #DEE6F5;
    }

    .tab-btn-active {
      border: none;
      background: #283445;
      color: white;
    }
  }
}

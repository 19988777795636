.auction-reports {

  &__no-reports {
    background: #EDF1FA;
    border-radius: 8px;
    padding: 48px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
  }

  &__no-reports-title {
    font-size: 22px;
  }

  &__list {
    margin: 24px 0;
  }

  .report_link {
    font-weight: 700;
    color: #283445;
    gap: 3px;
  }
}


.car-checks {
  display: flex;
  align-items: center;
  border-top: 1px solid #DEE6F5;
  margin-top: 16px;
  padding-top: 16px;
  gap: 10px;

  &__automart {
    display: flex;
    gap: 4px;
    font-size: 14px;
    align-items: center;
    padding: 6px 10px;
    border-radius: 6px;
    line-height: 16px;
  }

  &__automart_seller {
    background-color: #EDF1FA;
    color: #656C7B;
  }

  &__automart_automart {
    background-color: rgba(60, 131, 237, 0.1);;
    color: #2D78E9;
  }

  &__check-icons {
    margin-left: 30px;
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__shield {
    display: inline-flex;
  }

  &__btn {
    display: flex;
    gap: 8px;
    background-color: #F4F6FB;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    color: #2D78E9;
    font-weight: 700;
  }
}

@media screen and (max-width: 780px) {

  .car-checks {
    flex-direction: column;
    margin-top: 12px;
    padding-top: 12px;
    width: 100%;

    &__btn {
      width: 100%;
      padding: 12px;
    }

    &__automart,
    &__check-icons {
      gap: inherit;
    }

    &__check-icons {
      margin-left: 0 !important;
    }
  }
}

.terms-of-cooperation-modal-wrapper .modal-content {
  width: 540px;
  display: flex;
  gap: 20px;
  padding: 40px;
}

.terms-of-cooperation-modal-wrapper .modal-dialog {
  display: flex;
  justify-content: center;
}

.choose-profile-popup .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.choose-profile-popup {
  .modal-content {
    width: 480px;
    background-color: #F4F5FB !important;
    padding: 24px;
  }

  &__title {
    font-size: 22px;
    font-weight: 700;
    text-align: center;
  }

  &__list {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 1px solid #DEE6F5;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 18px;
  }
}

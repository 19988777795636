.btn-primary,
.btn-secondary,
.btn-ghost {
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  border-radius: 8px;
  padding: 10px 25px;
}

.btn-primary {
  background: #3c83ed;
}

.btn-secondary {
  border: #E0E7F5 2px solid;
  background-color: white;
  color: #283445;

  &:hover, &:focus, &:active {
    background-color: #f1f1f1;
    border-color: #E0E7F5;
    color: #283445;
    box-shadow: none;
  }

  &:active:focus {
    box-shadow: none;
    outline: none;
  }
}

.btn {

  &_l {
    height: 44px;
    padding: 10px 25px;
  }

  &_m {
    height: 40px;
    padding: 8px 20px;
  }

  &_s {
    height: 36px;
    padding: 6px 15px;
  }

  &_xs {
    height: 32px;
    padding: 4px 10px;
    font-size: 14px;
  }
}

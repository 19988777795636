.add-auction-wrapper {
  position: relative;
  display: grid;
  align-items: center;
  margin: 40px auto;
  width: 840px;

  .back-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    font-size: 14px;
    color: #656C7B;
  }

  .head-text {
    font-weight: 700;
    font-size: 22px;
    line-height: 135%;
    color: #283445;
  }

  .name {
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    color: #283445;
  }

  .info-wrapper {
    padding: 15px 20px;
    background: #FFFFFF;
    border: 1px solid #DEE6F5;
    box-sizing: border-box;
    border-radius: 8px;
  }

  .back-link {
    margin-bottom: 10px;
  }

  .btn-wrapper {
    display: flex;
    background: #EDF1FA;
    border: 1px solid #DEE6F5;
    border-radius: 8px;
    padding: 2px;
    color: grey;
  }

  .btn-wrapper__type {
    display: flex;
    font-size: 16px;
    padding: 6px 10px;
    gap: 8px;
    font-weight: 500;
    color: #656C7B;
    border: 1px solid transparent;
  }

  .btn-wrapper__type:hover {
    color: #2C3148;
  }

  .btn-wrapper__time {
    display: flex;
    background: #EDF1FA;
    border: 1px solid #DEE6F5;
    border-radius: 6px;
    padding: 2px;
    gap: 2px;
    color: grey;
    height: 36px;
  }

  .btn-wrapper__time-type {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 0 8px;
    font-weight: 500;
    color: #656C7B;
  }

  .btn-wrapper__type-active {
    border-color: #DEE6F5;
    border-radius: 6px;
    background: #FFFFFF;
    color: #2C3148;
  }

  .btn-wrapper__time-type:hover {
    color: #2C3148;
  }

  .input-time {
    max-width: 20%;
  }

  .lots-wrapper {
    position: relative;
  }

  .auction-buttons-footer .btn {
    padding: 12px 20px !important;
  }

  .suggestions {
    width: 100%;
  }

  .input-date {
    max-width: 284px;
  }

  .text-seller {
    font-size: 14px;
  }

  .tooltip-wrapper {
    width: 24px;
    height: 24px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F2F6FF;
    border: 1px solid #DEE6F5;
    border-radius: 50%;
  }

  .lot__buttons-top {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  }

  .lot__table {
    border: 1px solid #dee2e6;
    font-size: 14px;
    background-color: #FAFBFF;
    border-radius: 8px;
  }

  .lot__table-item-tr-car-link {
    color: #2D78E9;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    text-decoration-line: underline !important;
  }

  .lot__table-item-tr {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #656C7B;
  }

  .lot__table-item-row {
    border-bottom: 1px solid #dee2e6;
    padding: 0 6px 0 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 30px;
    align-items: center;
    height: 40px;

    &:last-child {
      border-bottom: 0;
    }
  }

  .add-auction__copy-link {
    color: #656C7B;
    font-weight: 700;
    line-height: 17px;
    font-size: 14px;
  }

  .steps-container .step-item:last-child {
    width: 100px;
    flex-shrink: 0;
  }

  .info-wrapper__input_disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}

.steps {
  display: flex;
  width: 100%;

  .steps-container {
    display: flex;
    width: 100%;
  }

  .step-item {
    padding: 10px 0;
    width: 100%;
    opacity: 0.4;
    cursor: pointer;

    .step-item__value {
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      color: #3c83ed;
      margin-bottom: 7px;
    }

    .step-item-indicator {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      position: relative;

      .step-item-indicator__cirle {
        position: relative;
        z-index: 2;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #dee6f5;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .step-item-indicator__cirle-inside {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #3c83ed;
        }
      }
      .step-item-indicator__line {
        position: absolute;

        z-index: 1;
        height: 5px;
        width: 100%;
        background: #dee6f5;
        border-radius: 5px 0px 0px 5px;
      }
    }

    .step-item-description {
      font-weight: 700;
      font-size: 12px;
      line-height: 140%;
    }
  }

  .step-item:last-child {
    width: max-content;
  }
  .active {
    opacity: 1 !important;
  }
}

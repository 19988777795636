.info-lot-wrapper, .lots-wrapper {
  padding: 0 0 16px;
  box-sizing: border-box;
}

.info-lot-wrapper {
  margin-top: 40px;
}

.lot-form__buttons {
  border-top: 1px solid #DEE6F5;
}

.info-wrapper__header {
  padding: 12px 16px;
  background: #EDF1FA;
  border-radius: 8px 8px 0 0;
  color: #656C7B;
}

.info-lot-wrapper__body {
  padding: 24px;
  background-color: #FFF;
}

.btn-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  gap: 16px;
  border-top: 1px solid #DEE6F5;
  bottom: 0;
  width: 100%;
  background: #F4F6FB;
}

.back-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    font-size: 14px;
    color: #656C7B;
}

.head-text {
    font-weight: 700;
    font-size: 22px;
    line-height: 135%;
    color: #283445;
}

.name {
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #283445;
}

.btn-wrapper {
  display: flex;
  background: #EDF1FA;
  border: 1px solid #DEE6F5;
  border-radius: 6px;
  padding: 2px;
  gap: 2px;
  color: grey;
}

.btn-wrapper__type {
  display: flex;
  font-size: 16px;
  padding: 6px 10px;
  gap: 8px;
  font-weight: 500;
  color: #656C7B;
}

.btn-wrapper__time {
  display: flex;
  background: #EDF1FA;
  border: 1px solid #DEE6F5;
  border-radius: 6px;
  padding: 2px;
  gap: 2px;
  color: grey;
  height: 36px;
}

.btn-wrapper__time-type {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 0 8px;
  font-weight: 500;
  color: #656C7B;
}

.auction-lots .input-light {
  padding: 9px 20px 11px;
  color: #9CA3B0;
  max-width: 540px;
  width: 100%;
  font-size: 14px;
  line-height: 150%;
  background: #FAFBFF;
  border: 1px solid rgba(156, 163, 176, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;

  &:disabled {
    opacity: 0.7;
  }
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tooltip-wrapper {
  width: 24px;
  height: 24px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F2F6FF;
  border: 1px solid #DEE6F5;
  border-radius: 50%;
}

.lot__buttons-top {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

.info-lot-wrapper {
  &__row {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 30% 70%;
    align-items: center;
    margin-top: 16px;
  }

  &__input-blitz {
    max-width: 255px;
  }
}



.users-tab-wrapper,
.group-tab-wrapper {

  .title {
    font-weight: bold;
    font-size: 22px;
    line-height: 135%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #283445;
  }

  .arrow-down {
    margin-left: 20px;
  }

  .filters-wrapper {
    margin: 25px 0 15px 10px;

    .input, .react-select {
      width: 320px;
    }

    .filter {
      padding: 20px;
      margin-top: 15px;
      background: white;
      border: 1px solid #DEE6F5;
      box-sizing: border-box;
      border-radius: 8px;

      & > div {
        gap: 10px;
      }
    }
  }

  .statistics-wrapper {
    margin: 25px 0 0 10px;
  }

  .users-list {
    margin: 32px 0 0 10px;

    .header {
      button {
        margin-left: 40px;
      }
    }
  }

  hr {
    background: #a2a5a7;
  }
}

.users-table-wrapper {
  .result {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #283445;
  }

  .table > :not(:first-child) {
    border-top: unset !important;
  }

  .order-4,
  .caret-4-asc,
  .caret-4-desc {
    float: right;
  }

  .css-6j8wv5-Input {
    width: 120px;
  }

  .selection-input-4 {
    margin: 2px 0 1px -2px !important;
  }

  thead tr th {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #283445;
    vertical-align: top;
  }

  @media screen and (max-width: 780px) {

    .users-table-wrapper__table {
      td, th {
        word-break: break-word;
      }
    }
  }
}

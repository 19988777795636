.input-requisites-search__with-icon {
  padding-left: 34px;
}

.input-requisites-search__icon {
  position: absolute;
  top: 50%;
  left: 6px;
  margin-top: -14px;
  cursor: pointer;
  padding: 6px;
  box-sizing: border-box;
  width: 28px;
  height: 28px;

  path {
    fill: #8A92A2;
  }

  &:hover path {
    fill: #2D78E9;
  }
}

.choose-seller-modal-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  .modal-dialog {
    max-width: 1016px;
  }

  .modal-content {
    background-color: #F4F5FB !important;
    padding-bottom: 0;
  }

  .table-class-wrap {
    max-height: 400px;
    overflow-y: auto;
  }

  .pagination-modal-wrapper {
    background-color: #FFFFFF;
    margin: -6px -35px 0 -35px;
    border-top: 1px solid #DEE6F5;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    .pagination-wrapper {
      justify-content: start;
      padding: 24px;
      margin-top: 0;
      box-shadow: 0px -8px 10px 0px rgba(40, 52, 69, 0.08);

      .result {
        color: #656C7B;
      }
    }
  }

  th {
    height: 40px;
    cursor: auto;
  }

  td {
    height: 44px;
    cursor: pointer;
  }

  .user-filters, .companies-filters {
    padding: 20px;
    border: 1px solid var(--main-color-light-blue);
    background-color: #FFF;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 20px;
    margin-top: 25px;
  }

  .user-filters {
    .input, .react-select {
      width: 218px;
    }
  }

  .companies-filters {
    .input {
      max-width: 294px;
      width: 100%;
    }
  }
}

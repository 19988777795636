.gallery {

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 21;
        background: rgba(0, 0, 0, 0.8);

        @media screen and (max-width: 600px) {
            z-index: 21;
        }
    }

    &__wrapper {
        padding: 20px;
    }

    &__close {
        position: absolute;
        top: 34px;
        right: 34px;
        width: 40px;
        height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        fill: #CCC;
        cursor: pointer;
        border-radius: 8px;
        z-index: 10;

        svg {
            width: 32px;
            height: 32px;
        }

        &:hover svg {
            fill: #FFF;
        }
    }
}

.image-gallery-content .image-gallery-slide {
    height: calc(100svh - 130px);
}

.fullscreen.image-gallery-content .image-gallery-slide {
    height: calc(100svh - 110px);
}

.image-gallery-content .image-gallery-slide .image-gallery-image,
.fullscreen.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 100%;
}

.image-gallery-thumbnails {
    padding: 20px 0 0;
}

.image-gallery-thumbnail {
    //width: 72px;
    height: 72px;
    border: 2px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    box-sizing: border-box;
    transition: none;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
    border: 2px solid #3C83ED;
}

.image-gallery-thumbnail-image {
    border-radius: 2px;
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner,
.image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 100%;
}

.image-gallery-thumbnail + .image-gallery-thumbnail {
    margin-left: 8px;
}

.image-gallery-thumbnail:hover:not(.active) {
    border: 1px solid #CCC;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 60px;
    stroke: #CCC;

    &:hover {
        stroke: #FFF;
    }
}

.image-gallery-icon:focus {
    outline: none;
}

.image-gallery-icon:focus .image-gallery-svg {
    stroke: #CCC;
}

.image-gallery-slide .image-gallery-description {
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
}

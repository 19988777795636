.lots-confirm-popup {

  &__table {
    max-height: 500px;
    overflow-y: auto;
    margin-top: 16px;
  }

  .modal-content {
    width: 100%;
    max-width: 640px;
    background-color: #F4F6FB !important;
    padding: 24px;
  }

  div.modal-dialog {
    width: 100%;
    max-width: 640px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }

  &__header-text {
    font-size: 22px;
    font-weight: 700;
  }

  &__header-text-step {
    font-size: 12px;
    font-weight: 600;
    color: #656C7B;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    gap: 10px;
  }

  &__form {
    display: flex;
    background-color: #FFFFFF;
    border: 1px solid #DEE6F5;
    border-radius: 8px;
    padding: 16px;
    margin-top: 24px;
    height: 100%;
    gap: 16px;

    &-description {
      font-size: 14px;
      color: #656C7B;
    }

    &-textarea {
      resize: none;
      width: 100%;
      height: 440px;
      padding: 16px;
      color: #283445;
      font-size: 16px;
      line-height: 18px;
      box-sizing: border-box;
      outline: none;
      font-weight: 500;
      border: 1px solid #2D78E9;
      border-radius: 8px;
    }
  }

  .table-link {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #2D78E9;
    text-decoration: underline !important;
  }

  .table__text-extra {
    color: #656C7B;
    font-size: 12px;
    line-height: 16px;
  }

  .btn-secondary, .btn-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .btn-secondary {
    width: 38%;
  }
}

.auction-down {

  &__price-icon {
    margin-right: 11px;
  }

  &__prestart-timer {
    position: absolute;
    z-index: 7;
    background: #DEE6F5;
    border: 1px solid #D4DEF3;
    border-radius: 8px 8px 0 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px
  }
}

.companies-wrapper {
  padding: 20px 30px;

  .filters-wrapper {
    margin: 25px 0 15px 0;

    .filter {
      padding: 20px;
      margin-top: 15px;
      background: white;
      border: 1px solid #DEE6F5;
      box-sizing: border-box;
      border-radius: 8px;

      & > div {
        gap: 10px;
      }

      .input, .react-select {
        width: 265px;
      }
    }
  }

  .input, .react-select {
    max-width: 265px;
  }

  .arrow-down {
    margin-left: 20px;
  }

  .name {
    font-weight: 700;
    font-size: 22px;
    line-height: 135%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #283445;
  }
}


.users-department-modal {

  .modal-content {
    width: 100%;
    padding: 25px 35px;
    border: 1px solid var(--main-color-light-blue);
    box-sizing: border-box;
    border-radius: 8px;
  }

  .filters-wrapper {
    margin: 25px 0 15px 10px;

    .filter {
      padding: 20px;
      margin-top: 15px;
      background: white;
      border: 1px solid var(--main-color-light-blue);
      box-sizing: border-box;
      border-radius: 8px;
    }

    .input, .react-select {
      width: 260px;
    }
  }
}

.exchange {
  margin: 40px 30px;

  .head-text {
    font-weight: bold;
    font-size: 22px;
    line-height: 135%;
    color: #283445;
  }

  .name {
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    color: #283445;
  }

  .info-wrapper,
  .lots-wrapper {
    padding: 15px 20px;
    background: #FFFFFF;
    border: 1px solid #DEE6F5;
    box-sizing: border-box;
    border-radius: 8px;
  }

  .lots-wrapper {
    position: relative;
  }

  .input {
    width: 540px;
  }

  .tooltip-wrapper {
    width: 24px;
    height: 24px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F2F6FF;
    border: 1px solid #DEE6F5;
    border-radius: 50%;
  }

  .lot__buttons-top {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  }

  .lot__button-top {
    background: #DEE6F5;
    color:  #3C83ED;
    font-weight: bold;
    border: 1px solid #3C83ED;
    cursor: pointer;

    &:hover {
      opacity: 0.85;
    }

    &_last {
      border-radius: 0 8px 0 0;
      border-right: 1px solid #3C83ED;
    }
  }

  .lot__table {
    border-collapse: collapse;
    border: 1px solid #dee2e6;
    font-size: 14px;
  }

  .lot__table-item-tr {
    border: 1px solid #dee2e6;
    padding: 8px;
    font-size: 14px;
  }
}

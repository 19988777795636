.icon-view-wrapper {
  display: flex;
  align-items: center;
  margin-right: 28px;

  .icon-view {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    cursor: pointer;

    &_selected {
      background: rgba(60, 131, 237, 0.1);
      border-radius: 6px;
    }
  }
}

.radio-container {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;

  &_disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}

.radio-btn-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-btn {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #9ca3b0;
}

.radio-container:not(.radio-container_disabled):hover input ~ .radio-btn {
  background-color: #ccc;
}

.radio-btn:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .radio-btn:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .radio-btn:after {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #3c83ed;
}

.group-wrapper {
  padding: 25px 30px;

  .company-info {
    padding: 15px 30px;
    background: #FFFFFF;
    border: 1px solid #DEE6F5;
    box-sizing: border-box;
    border-radius: 8px;
  }

  .name {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #283445;
  }

  .value {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #283445;
  }
}

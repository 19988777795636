.car-wrapper {
  position: relative;
  z-index: 6;
  padding: 8px;
  background: #FFFFFF;
  border: 1px solid var(--main-color-light-blue);
  box-sizing: border-box;
  border-radius: 8px;
  color: #283445;

  .car-name {
    margin-top: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #283445;
  }

  hr {
    margin: 15px 23px;
    background: var(--main-color-light-blue);
  }

  .value {
    margin: 2px 0 0 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #283445;
  }

  .w-60 {
    width: 60%;
  }

  .w-40 {
    width: 40%;
  }

  .vin-title {
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #283445;
  }

  .vin {
    margin-left: 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #3C83ED;
  }

  .icon {
    width: 13px;
    height: 26px;
    margin-right: 6px;
  }
}

.car-card {

  &__main-image {
    border-radius: 5px;
    width: 240px;
    height: 150px;
    object-fit: cover;
  }

  &__block {
    background: #F4F6FB;
    opacity: 0.5;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
  }

  &__info-block {
    display: grid;
    grid-template-areas: "a b" "c d" "e f";
  }

  &__info-block-a {
    grid-area: a;
  }

  &__info-block-b {
    grid-area: b;
  }

  &__info-block-c {
    grid-area: c;
  }

  &__info-block-d {
    grid-area: d;
  }

  &__info-block-e {
    grid-area: e;
  }

  &__info-block-f {
    grid-area: f;
  }

  &__header-line {
    margin: 15px 0;
    background: #dee6f5;
    height: 1px;
  }
}

.lot-cars__wrapper.horizontal {
  flex-direction: column;

  .car-wrapper {
    min-height: 168px;
    display: grid;
    grid-template-columns: 240px 1fr;
    gap: 30px;
    padding-right: 30px;
  }

  .car-name {
    font-size: 20px;
    margin-top: 0;
  }

  .car-card__info-block {
    grid-template-areas: "a b e" "c d f";
  }
}

.tile .car-wrapper .car-name {
  display: block;
  width: 240px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 780px) {
  .car-wrapper {
    width: 100%;

    .car-card__main-image {
      width: 100%;
    }

    .car-card__info-block {
      margin-top: 10px;
    }
  }
}

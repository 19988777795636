.documents {
  background-color: #FFF;

  &__list {
    display: flex;
    background-color: #FFF;
    gap: 8px;
    flex-wrap: wrap;


      .file {
        width: auto;
        border: 1px solid #DEE6F5;
        border-radius: 8px;
        padding: 8px 14px;

        .download-file {
          display: flex;
          gap: 8px;

          .fileName {
            color: black;
            font-weight: 600;
            font-size: 14px;
          }

          .fileInfo {
            color: #656C7B;
            font-size: 14px;
            font-weight: 500;
          }

          .download-file-icon {
            width: 30px;
          }
        }
      }
  }
}

@media screen and (max-width: 780px) {
  .documents__list {
    display: flex;
    flex-direction: column;
    max-width: 100% !important;
    gap: 8px;

    .row {
      flex-wrap: nowrap;
    }
  }
}

.car-summary-wrapper {
  &__auction {
    margin: 16px 0;

    &-field {
      display: flex;
      margin-top: 8px;

      .name {
        width: 140px;
      }

      .commission {
        display: flex;
        align-items: center;
        line-height: 16px;
        gap: 3px;

        img {
          margin-top: 2px;
        }
      }
    }
  }
}

.pagination-wrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  .pagination {
    margin: 0;
    flex-wrap: wrap;
  }

  .input-light-pagination {
    padding-left: 12px;
    width: 70px;
    height: 34px;
    font-weight: 500;
    color: black !important;
    font-size: 14px;
    line-height: 150%;
    outline: none;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #dee2e6;
    flex-shrink: 0;
  }

  .active-page .page-link {
    background-color: #dee2e6;
  }

  .page-link {
    background-color: #FFF;
    color: #283445;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    height: 34px;

    &:hover {
      color: #283445;
    }
  }

  .page-item:first-child .page-link {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .page-item:last-child .page-link {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .page-link:focus {
    color: inherit;
    box-shadow: none;
  }

  .page-arrow:not(.disabled) .page-link {
    color: #283445;
  }

  .page-item.disabled .page-link {
    color: lightgray;
  }
}

@media screen and (max-width: 780px) {
  .pagination-wrapper {
    justify-content: space-between;
  }
}

.create-user {
  padding: 20px 30px;

  &-wrapper {
    padding: 20px 30px 30px;
    background: #FFFFFF;
    border: 1px solid #DEE6F5;
    box-sizing: border-box;
    border-radius: 8px;

    &__field {
      display: flex;
      align-items: center;
      margin-top: 8px;

      &-name {
        width: 30%;
        font-weight: bold;
        font-size: 16px;
        line-height: 140%;
        color: #283445;
      }

      &-password {
        position: relative;
        width: 100%;
        max-width: 540px;
      }

      &-deposit {
        display: flex;
        gap: 10px;
        align-items: center;
        height: 44px;
      }

      &-roles {
        display: flex;
        position: relative;
        gap: 16px;

        .form-check-inline {
          margin-right: 0;
        }
      }

      .input,
      .react-select {
        width: 70%;
        max-width: 540px;
      }
    }
  }

  .head-text {
    font-weight: bold;
    font-size: 22px;
    line-height: 135%;
    color: #283445;
  }

  .tooltip-wrapper {
    width: 24px;
    height: 24px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F2F6FF;
    border: 1px solid #DEE6F5;
    border-radius: 50%;
  }

  .btn-primary {
    margin: 15px 0;
  }
}

@media screen and (max-width: 780px) {
  .create-user {
    padding: 20px;

    &-wrapper {
      padding: 20px 15px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      &__field {
        flex-direction: column;
        gap: 6px;

        .input,
        .react-select {
          width: 100%;
        }

        &-name {
          width: 100%;
          text-align: start;
          font-weight: 600;
        }

        .input,
        .react-select {
          width: 100%;
        }
      }

      .roles, .deposit {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .document-field__wrapper {
      .info-field-wrapper {
        display: flex;
        flex-direction: column;
        gap: 6px;
        margin-top: 0;

        .document-field__label {
          font-weight: 600;
        }

        .loaded-file {
          width: 100%;
        }
      }
    }
  }
}

.input-upload-photo-wrapper {
  width: 540px;

  &__dashed-border {
    border: 2px dashed #DEE6F5;
    border-radius: 5px;
  }

  &__plus {
    color: #8A92A2;
    font-size: 64px;
    line-height: 75px;
    box-sizing: border-box;
  }

  &__text {
    color: #8A92A2;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    margin-bottom: 10px;
  }

  &__form-control {
    display: none;
  }
}

.single-car-wrapper {
  padding: 70px 30px 0;

  &__general-info {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      gap: 20px;
      align-items: flex-start;
    }
  }

  &__lot-info-top {
    position: fixed;
    display: flex;
    align-items: center;
    background-color: #414B5B;
    height: 44px;
    padding: 0 16px;
    box-sizing: border-box;
    margin: -70px 0 0 -30px;
    color: #FFF;
    width: calc(100% - var(--vars-left-menu-width));
    z-index: 9;
    gap: 10px;

    .timer-icon {
      display: none;
    }

    .timer {
      color: #FFF;
    }
  }

  .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #656C7B;
  }

  .value {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #283445;
  }

  &__link-to-current-trades {
    display: flex;
    gap: 2px;
    color: #656C7B;
    font-weight: 700;
    font-size: 14px;
    margin-top: 44px;
  }

  .car-name {
    font-weight: 700;
    font-size: 22px;
    line-height: 135%;
    color: #283445;
  }

  .status {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 5px 12px 6px;
    border-radius: 6px;
    font-weight: 700;
    font-size: 12px;
    box-sizing: border-box;
    border: none;

    svg {
      border-left: 1px solid #2834451F;
      padding: 0 0 0 4px;
    }
  }

  .green {
    background: #6AC96E1A;
    color: #339337;

    svg {
      fill: #339337;
    }
  }

  .orange {
    background: rgba(254, 179, 92, 0.1);
    color: #F17B00;

    svg {
      fill: #F17B00;
    }
  }

  .red {
    background: rgba(240, 68, 97, 0.1);
    color: #F04461;

    svg {
      fill: #F04461;
    }
  }

  .car-info {

    .swiper-slider {
      width: 346px;
      height: 300px;
    }

    &.width-100 {
      width: 100%;
    }
    &-general {
      display: flex;
      gap: 24px;
    }

    padding: 16px;
    background: #ffffff;
    border: 1px solid #dee6f5;
    box-sizing: border-box;
    border-radius: 8px;
  }

  .info {
    .checked {
      margin: 0 23px 0 8px;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      color: #9ca3b0;
    }

    .imgs {
      img {
        margin-right: 17px;
      }
    }

    &__comment {
      padding-top: 10px;
      border-top: 1px solid #DEE6F5;
    }
  }

  .slick-slider {
    width: 530px;
  }

  .slick-next,
  .slick-prev {
    background: #fff;
    border-radius: 50%;
  }

  .slick-prev {
    width: 27px;
    height: 25px;
    left: 12px;
    z-index: 99;
  }

  .slick-next {
    width: 27px;
    height: 25px;
    right: 11px;
  }

  .slick-next:before,
  .slick-prev:before {
    color: #3c83ed;
    opacity: 1;
    font-size: 29px;
  }

  .slick-dots {
    bottom: -30px;
    left: -5px;

    .slick-active {
      img {
        border: 4px solid #3c83ed;
        box-sizing: border-box;
        border-radius: 5px;
      }
    }

    img {
      width: 122px;
    }

    li {
      width: unset;
    }
  }

  .tabs {
    flex-wrap:wrap;

    .tab-btn {
      outline: none;
      padding: 10px 30px;
      border-radius: 60px;
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
      color: #283445;
      background: white;
      border: 1px solid #DEE6F5;
    }

    .tab-btn-active {
      border: none;
      background: #283445;
      color: white;
    }
  }
}

.auction {
  margin: 16px 0;

  .field, .info__comment {
    display: flex;
    margin-top: 8px;
  }

  &>.right-side {
    margin-left: 16px;
  }
}

.car-lot-info {
  position: fixed;
  bottom: 0;
  z-index: 20;
  right: 0;
  background: #F4F6FB;
  min-height: 70px;
  border: 1px solid #EFF3FA;
  padding: 0;
  border-radius: 0;

  &__button {
    border: 2px solid #DEE6F5;
    border-radius: 8px;
    padding: 10px 16px;
    position: absolute;
    right: 40px;
    bottom: 8px;
  }

  .actions {
    margin-top: 12px;
  }

  .lot-car-wrapper {
    border-radius: 0;
  }
}

@media screen and (max-width: 780px) {
  .single-car-wrapper__lot-info-top {
    margin: -18px 0 0 -12px;
    width: 100%;
  }

  .single-car-wrapper {
    .car-info {
      .swiper-slider {
        width: 100%;
        height: auto;
      }
    }
  }

  .swiper-slider__thumbs {
    display: none;
  }

  .mob-car {
    &_title-wrapper {
      flex-direction: column;

      .mob-button-width {
        padding-top: 4vw;
        width: 80%;
      }
    }

    &_title {
      flex-direction: column-reverse;
      width: 100%;

      .car-status {
        width: 30%;
        text-align: center;
      }
    }
  }

  .mob-button-width {

    .action {
      width: 100%;

      .dropdown {
        width: 100% !important;

        .btn-primary {
          width: 100%;
          line-height: 2;
          font-size: 16px !important;
          border-radius: 10px !important;
        }
      }
    }
  }

  .info {
    margin-left: 0 !important;
    margin-top: 4vw;
    display: flex;
    flex-direction: column-reverse;

    .vehicle {
      display: none;
    }

    .d-flex {
      flex-direction: column;
      gap: 2vw;

      .left-side,
      .right-side {
        display: inherit;
        flex-direction: inherit;
        gap: inherit;
        margin-left: 0;
      }
    }
  }

  .section {
    .react-bootstrap-table {
      th {
        width: 40vw;
        padding: 4vw;
      }
    }
  }

  .tab-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    white-space: nowrap;
    padding: 10px 15px !important;
  }

  .car-lot-info {
    height: auto;

    &__button {
      position: static;
      right: 0;
      bottom: 0;
      margin: 0 10px 5px 10px;
    }
  }
}


@media screen and (max-width: 780px) {
  .single-car-wrapper {
    padding: 18px 12px;

    .car-info {
      flex-direction: column;
      padding: 10px;
      margin-top: 18px !important;;
    }
  }
}

@media screen and (min-width: 780px) and (max-width: 1200px) {
  .single-car-wrapper {

    .auction {
      margin-top: 0;
    }

    .info {
      margin-left: 16px;

      .vehicle {
        margin-right: 20px;
      }
    }

    .equipmentWrapper {
      .sale-section {
        min-width: 200px;
      }
    }
  }

  .auction {
    margin-top: 0;
  }
}

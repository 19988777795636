.buy-wrapper {
  .last-bet-is-my {
    display: inline-flex;
    padding: 8px 12px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    border-radius: 6px;
    background: rgba(106, 201, 110, 0.1);
    color: #339337;
    align-items: center;
    justify-content: center;
  }

  .last-bet-is-not-my {
    display: inline-flex;
    padding: 8px 12px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    border-radius: 6px;
    background: rgba(240, 68, 97, 0.1);
    color: #F04461;
    align-items: center;
    justify-content: center;
  }

  td {
    padding: 6px !important;
  }

  .table__file-extra-text {
    color: #656C7B;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }

  .table__file-blue-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #2D78E9;
    text-decoration: underline !important;
  }

  .filter {

    & > div {
      gap: 10px;
    }
  }

  .min-height {
    min-height: 60px;
  }
}

.swiper-wrapper {
  user-select: none;
  transition-property: unset;
}

.swiper-slider {
  width: 530px;

  .swiper-slide img {
    display: block;
    width: 100%;
    object-fit: contain;
    border-radius: 0.8rem;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-color: #3C83ED;
    border-radius: 50%;
    background-image: url("../../../public/img/arrow-left.svg");
    background-repeat: no-repeat;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    background-position: 11px center;
    margin-top: -15px;

    &:after {
      content: none
    }
  }

  .swiper-button-disabled {
    cursor: default;
  }

  .swiper-button-next {
    transform: rotate(180deg);
  }

  .swiper-thumbs {
    margin-top: 10px;
    width: 100%;

    .swiper-slide {
      border: 4px solid transparent;

      img {
        border-radius: 0;
        height: 100%;
        object-fit: cover;
      }
    }

    .swiper-slide-thumb-active {
      border-radius: 4px;
      border: 4px solid #3C83ED;

      & > img {
        border-radius: 0;
      }
    }
  }

  &__thumbs {
    height: 78px;
    margin-top: 10px;
  }

  &__main {
    height: 100%;
    width: 100%;
    max-height: 300px;
    max-width: 530px;
    cursor: pointer;
  }

}

@media screen and (max-width: 600px) {
  .swiper-slider {
    width: 100%;
    height: auto;
  }

  .image-gallery-slides {
    display: flex;
    justify-content: center;
  }

  .gallery__close {
    top: 6px;
    right: 6px;
  }
}

.reporting-wrapper {
  padding: 30px 40px;

  .reporting-wrapper__head-text {
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
  }

  .tabs-wrapper {
    padding-top: 0;
  }

  .text-extra-info {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #656C7B;
  }

  .text-name-info {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #283445;;
  }

  .reporting-form-wrapper {
    background: #FFFFFF;
    border: 1px solid #DEE6F5;
    border-radius: 8px;
    padding: 16px 24px;
    margin: 24px 0;
  }

  .input-date-width {
    width: 144px;
  }

  .reporting-form-wrapper__textarea {
    resize: none;
    width: 100%;
    height: 154px;
    padding: 16px;
    color: #283445;
    font-size: 16px;
    line-height: 18px;
    background: #F4F6FB;
    border: 1px solid rgba(156, 163, 176, 0.1);
    box-sizing: border-box;
    border-radius: 6px;
    outline: none;
    font-weight: 500;

    &:disabled {
      opacity: 0.7;
      cursor: default;
    }

    &::placeholder {
      color: #9CA3B0;
    }
  }

  .reporting-form-wrapper__textarea:hover {
    border: 1px solid #8A92A1;
  }

  .reporting-wrapper__message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px 64px;
    background: #EDF1FA;
    border-radius: 8px;
  }

  .reporting-wrapper__message-title {
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #8A92A2;
  }

  .reporting-wrapper__message-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #8A92A2;
  }

  .suggestions {
    width: 70%;
  }

  .btn-wrapper__time-type {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 0 8px;
    font-weight: 500;
    color: #656C7B;
  }

  .btn-wrapper__type-active {
    border-color: #DEE6F5;
    border-radius: 6px;
    background: #FFFFFF;
    color: #2C3148;
  }

  .btn-wrapper__time-type:hover {
    color: #2C3148;
  }

  td {
    height: 64px;
    padding: 12px !important;
  }

  .border {
    border: 1px solid #DEE6F5;
    border-bottom: 0;
  }
}

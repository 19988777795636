.notifications {

  &__auto-bet {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 90px;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 10px 12px;
    border-radius: 6px;
    background-color: rgba(40, 52, 69, 0.88);
    color: #FFF;
    z-index: 1000;
    max-width: 600px;

    &-text {
      margin-right: 6px;
    }

    &-close {
      cursor: pointer;
      display: inline-flex;
      padding: 4px;
    }
  }
}

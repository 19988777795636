.tooltip-wrapper {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F2F6FF;
  border: 1px solid var(--main-color-light-blue);
  border-radius: 50%;
  cursor: pointer;
}

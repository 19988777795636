.header-menu {
  border-left: 1px solid var(--main-color-light-blue);
  padding: 5px 30px;
  background: white;
  position: fixed;
  width: calc(100% - var(--vars-left-menu-width));
  z-index: 10;

  .dropdown-menu {
    margin-top: 2px;
    padding: 8px 0;
    gap: 8px;
    min-width: 240px;
    background: #FFFFFF;
    border: 1px solid #DEE6F5;
    box-shadow: 0 4px 12px rgba(40, 52, 69, 0.24);
    border-radius: 8px;
  }

  .dropdown-item {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 16px 12px;
    height: 44px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 14px;
    color: #283445;
  }

  .dropdown-item:last-child {
    border-top: 1px solid #DEE6F5;
  }

  .dropdown-item-logout {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }

  .price-wrapper {
    .price {
      margin-right: 17px;
      font-weight: 700;
      font-size: 14px;
      line-height: 140%;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #283445;
    }

    .btn-primary {
      width: 90px;
      border: none;
      outline: none;
      padding: 7px 12px 9px 12px !important;
      border-radius: 6px;
      font-size: 12px !important;
      line-height: 150%;
      text-align: center;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #ffffff;
    }
  }

  .notification {
    position: relative;
    margin-left: 48px;

    .message-count {
      position: absolute;
      top: 0;
      left: 10px;
      padding: 3px;
      background: #f04461;
      border-radius: 8px;
      font-weight: bold;
      font-size: 9px;
      line-height: 110%;
      letter-spacing: 0.008em;
      color: #ffffff;
    }
  }

  .messages {
    margin-left: 29px;
  }

  .user {
    .user-info {
      margin-left: 12px;

      .name {
        font-weight: bold;
        font-size: 13px;
        line-height: 150%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #11142d;
      }

      img {
        width: 20px; // ширина блока фиксирована, т.к. изменение ширины родителя может перезаписать её
        object-fit: scale-down;
        margin-left: 10px;
      }

      .info {
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.008em;
        color: #9ca3b0;
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .header-menu {
    width: 100%;
    padding: 5px 8px 5px 18px;

    img.burger-img {
      z-index: 10;
    }

    .user-info {

      .info {
        margin-top: unset;
      }

      .name {
        font-size: 12px !important;
      }
    }
  }
}

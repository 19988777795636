.input-wrapper {
  width: 100%;

  input:focus {
    outline: none;
  }

  .input-label {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    color: #283445;
  }

  .input-field-wrapper {
    width: 100%;
    background: #f4f6fb;
    border: 1px solid rgba(156, 163, 176, 0.1);
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #9ca3b0;

    .input-field {
      width: 100%;
      border: 0;
      background: transparent;

      &:disabled {
        opacity: 0.8;
      }
    }
  }
  .error {
    border-color: #f04461;
  }

  .success {
    border-color: #71d875;
  }

  .notice {
    display: none;
  }

  .error__show,
  .success__show {
    display: block;
  }
}

.transition-confirm-popup-wrapper {
  .modal-content {
    width: 480px;
    background-color: #F4F5FB !important;
    padding: 24px;
  }

  .modal-dialog {
    display: flex;
    justify-content: center;
  }

  &__header {
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
    text-align: center;
    margin-bottom: 8px;
  }

  &__text {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    margin-bottom: 24px;
  }

  &__btn-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 24px;
    border-top: 1px solid #DEE6F5;

    &-confirm {
      border: 1px solid #FE8DA0;
      border-radius: 8px;
      color: #F04461;
      background-color: #FFFFFF;
      font-size: 14px;
      font-weight: 700;
      padding: 10px;
    }
  }
}

.dropdown {

  .dropdown__button {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 15px !important;
    padding-right: 13px !important;
  }

  .dropdown__button-text {
    padding-right: 12px;
    border-right: 2px solid rgba(255, 255, 255, 0.32);
    margin-right: 2px;
  }

  .dropdown__button-arrow {
    transition: 200ms;
  }

  &.show .dropdown__button-arrow {
    transform: rotate(180deg);

  }
}

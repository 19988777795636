.change-password {
  padding: 15px 30px;
  background: #FFFFFF;
  border: 1px solid var(--main-color-light-blue);
  box-sizing: border-box;
  border-radius: 8px;

  &__field-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;

    &-name {
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      color: #283445;
      width: 30%;
    }

    &-input {
      display: flex;
      align-items: center;
      position: relative;
      width: 30%;
    }

    &-btns {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-top: 24px;
    }
  }

  .btn {
    width: 30%;
  }

  .btn-primary {
    display: flex;
    align-items: center;
    gap: 6px;
    justify-content: center;
    svg {
      fill: #FFFFFF;
    }
  }
}

@media screen and (max-width: 780px) {
  .change-password {
    padding: 16px;

    &__field-wrapper {
      flex-direction: column;
      justify-content: start;
      margin: 0 0 24px 0;

      &-name {
        text-align: start;
        width: 100%;
        font-weight: 600;
        margin-bottom: 6px;
      }
      &-input {
        width: 100%;
      }

      &-btns {
        flex-direction: column;
        padding-top: 16px;
        border-top: 1px solid #DEE6F5;
      }
    }
    .btn, .btn-primary {
      width: 100%;
    }
  }
}

.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;

  &_fixed {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.auctions-calendar {

  .rt-controls {
    display: none;
  }

  .rt-layout {
    border-radius: 1em;
    margin: 1em 1em 1em 1em!important; /* Последнее свойство перезаписывает стиль, накладываемый пропом либы isOpen */
  }

  .rt-sidebar {
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
  }

  .rt-sidebar__header {
    border-top-left-radius: 1em;
  }

  .rt-timebar-key {
    text-align: left;
    text-indent: 1.5em;
    border-top-left-radius: inherit;
    background-color: #E9EFFA;
  }

  .rt-layout__timeline {
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
  }

  .rt-element__tooltip {
    font-size: 14px;
    border: 1px solid #DEE6F5;
    background: white;
    box-shadow: 10px 5px 25px 5px rgb(12 12 12 / 10%);
    border-radius: 0.5em;
    color: #4c4c4c;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    position: fixed;
    padding: 0;
    margin: 30px 0 0 0;
    transform: scale(0);
  }

  .rt-element:hover > .rt-element__tooltip,
  .rt-element:focus > .rt-element__tooltip {
    transform: scale(1);
  }

  .rt-element__tooltip:before {
    display: none; /* В штатной библиотеке стилей к тултипу привязано изображение треугольника. Здесь он нам не нужен. */
  }

  .rt-element__tooltip div div {
    padding: 6px 10px;
    color: #283445;
    font-weight: 500;
  }

  .rt-element__tooltip div div b {
    color: #9CA3B0;
  }

  .rt-element {
    border-radius: 15px !important;
  }

  .rt-element__title {
    display: none;
  }

  .rt-marker {
    opacity: 0 !important;
  }

  .rt-timebar__cell {
    background-color: #E9EFFA;
    border-left: 1px solid #DEE6F5;
    font-weight: 800;
    font-size: 12px;
  }

  .rt-layout__timeline::-webkit-scrollbar {
    -webkit-appearance: none !important;
    opacity: 1;
  }

  .rt-layout__timeline::-webkit-scrollbar:vertical {
    width: 13px !important;
  }

  .rt-layout__timeline::-webkit-scrollbar:horizontal {
    height: 13px !important;
  }

  .rt-layout__timeline::-webkit-scrollbar-thumb {
    border-radius: 8px !important;
    border: 2px solid #AEB3D2; /*  через это свойство выделяется граница элемента скроллбара */
    background-color: white;
  }

  .rt-layout__timeline::-webkit-scrollbar-track {
    box-shadow: none !important;
    background-color: #fff;
    border-radius: 8px;
  }

  .rt-layout__main {
    width: calc(100% - 20%);
  }

  .rt-layout__side {
    width: 20%;
  }

  .rt-track-key__title {
    white-space: initial;
  }

  .rt-track-key__entry {
    line-height: 18px;
  }

  .rt-track-key__entry,
  .rt-track__elements {
    padding-left: 0 !important;
    height: 45px;
  }

  .rt-track-key__link {
    cursor: pointer;
    color: #0D6EFD;
    font-size: 14px;
    padding: 0 10px;
    align-items: center;
  }

  .rt-element {
    height: 30px;
  }

  .rt-track__element {
    height: auto;
    top: 7px;
  }

  @media screen and (max-width: 780px) {
    .rt-layout__main {
      width: calc(100% - 46%);
    }

    .rt-layout__side {
      width: 46%;
    }

    .rt-track-key__link {
      font-size: 12px;
      padding: 0 4px;
    }

    .rt-track-key__entry {
      line-height: 16px;
    }
  }
}

.react-select {
  font-size: 16px;
  width: 100%;
  line-height: 18px;
  background: #F4F6FB;
  border: 1px solid rgba(156, 163, 176, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  font-weight: 500;

  .select__value-container {
    padding: 0;
  }

  .select__placeholder {
    margin-left: 0;
  }

  &_l {
    height: 44px;

    &.react-select_multi {
      min-height: 44px;
    }
  }

  &_m {
    height: 40px;

    &.react-select_multi {
      min-height: 40px;
    }
  }

  &_s {
    height: 36px;

    &.react-select_multi {
      min-height: 36px;
    }
  }

  &_xs {
    height: 32px;
    font-size: 14px;

    &.react-select_multi {
      min-height: 32px;
    }
  }

  &_multi {
    height: auto;
    display: grid;
  }

  &_selected {
    color: #283445;
  }

  &:disabled {
    opacity: 0.7;
  }

  &_white {
    background-color: white;
  }
}

.react-select:hover {
  border: 1px solid #8A92A1;
}

.access-users-modal-wrapper {

  .modal-content {
    background-color: #F4F6FB !important;
    border: 1px solid var(--main-color-light-blue);
    box-sizing: border-box;
    border-radius: 8px;

    .head-text {
      font-weight: bold;
      font-size: 22px;
      line-height: 135%;
      color: #283445;
      margin-bottom: 25px;
    }

    .table {

      th, td {
        border: 0;
      }

      tr {
        border-left: 1px solid rgb(222, 226, 230);
        border-right: 1px solid rgb(222, 226, 230);
      }

      tr:first-child {
        border-radius: 8px 8px 0 0;
      }
    }
  }

  .user-access__filters {
    padding: 20px;
    border: 1px solid var(--main-color-light-blue);
    background-color: #FFF;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 20px;
    margin-top: 25px;

    .input, .react-select {
      width: auto;
      flex-grow: 1;
    }

    .filter {
      padding: 20px;
      margin-top: 15px;
      background: white;
      border: 1px solid var(--main-color-light-blue);
      box-sizing: border-box;
      border-radius: 8px;
    }
  }

  .user-group-access__filters .input {
    width: 290px;
    flex-grow: unset;
  }

  .tabs {
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 20px;

    .tab-btn {
      outline: none;
      padding: 10px 30px;
      border-radius: 60px;
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
      color: #283445;
      background: white;
      border: 1px solid #DEE6F5;
    }

    .tab-btn-active {
      border: none;
      background: #283445;
      color: white;
    }
  }
}

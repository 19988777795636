.car-sales {
  padding: 26px 30px;

  .tabs-wrapper {
    padding: 0;
  }

  &__content-head {
    display: flex;
    flex-direction: column;

    .btn-filters {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      gap: 4px;
      border: 2px solid #DEE6F5;
      border-radius: 6px;
      padding: 6px 0;
      background-color: #FFFFFF;
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      width: 112px;
    }

    @media screen and (max-width: 640px) {
      flex-direction: column-reverse;
      gap: 16px;
    }
  }

  &__content-head-actions {
    display: flex;
    justify-content: end;

    .btn-primary {
      flex-shrink: 0;
    }
  }

  .w-55 {
    width: 55%;
  }

  .w-45 {
    width: 45%;
  }

  .max-width {
    max-width: 140px;
  }

  .arrow-down {
    margin-left: 20px;
    transition: .3s all;
  }

  &__filters-wrapper {
    margin: 15px 0;

    .filter {
      padding: 16px;
      margin-top: 10px;
      background: white;
      border: 1px solid var(--main-color-light-blue);
      box-sizing: border-box;
      border-radius: 8px;

      .input, .react-select {
        width: 265px;
      }
    }
  }

  &__input-another {
    width: 180px;
  }

  &__input {
    width: 272px;
  }

  &__date-picker {
    padding: 8px 11px;
    color: #8080a0;
    width: 275px;
    font-size: 14px;
    line-height: 150%;
    background: #F4F6FB;
    border: 1px solid rgba(156, 163, 176, 0.1);
    box-sizing: border-box;
    border-radius: 6px;
    outline: none;
  }

  &__filters-buttons {
    display: flex;
    margin-top: 20px;
    gap: 20px;
  }

  &__statistics-wrapper {
    margin: 25px 0 0 0;
  }

  &__car {
    width: 276px;
    padding: 12px 14px;
    background: #FFFFFF;
    border: 1px solid var(--main-color-light-blue);
    border-radius: 8px;
  }

  &__car-head-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 135%;
    color: #283445;
  }

  &__car-content {
    padding: 8px;
    background: #F4F6FB;
    border: 1px solid var(--main-color-light-blue);
    box-sizing: border-box;
    border-radius: 8px;
  }

  &__car-image {
    border-radius: 5px;
    width: 228px;
    height: 142px;
    object-fit: cover;
  }

  &__car-name {
    margin-top: 15px;
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
    color: #283445;
  }

    hr {
      margin: 15px 0;
      background: var(--main-color-light-blue);
    }

  &__car-info-value {
    margin: 2px 0 0 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #283445;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__car-info-vin-title {
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    color: #283445;
  }

  &__car-info-vin {
    margin-left: 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #3C83ED;
  }

  &__car-info-icon {
    width: 13px;
    height: 26px;
    margin-right: 6px;
  }
}

@media screen and (max-width: 780px) {
  .car-sales {
    padding: 0 16px;

    .tabs-wrapper {
      display: none;
    }

    &__car {
      width: 100%;
      margin: auto;

      &-image {
        width: 100%;
      }
    }

    &__input-light {
      width: 100%;
    }
  }

  .filter .d-flex {
    gap: 20px;

    .suggestions {
      width: 100%;
    }
  }
}

.wrapper {
  width: calc(100% - var(--vars-left-menu-width));
}

@media screen and (max-width: 780px) {
  .wrapper,
  .wrapper.wrapper-full {
    width: 100vw !important;
  }

  .wrapper {

    .children {
      transition: filter 0.4s ease-in-out;
      filter: brightness(0.7) blur(1px);
      pointer-events: none;
    }

    &.wrapper-full .children {
      filter: unset;
      pointer-events: unset;
    }

    &.wrapper-full .header-menu .d-flex .user {
      display: none !important;
    }
  }
}

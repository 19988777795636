.info-car-wrapper {
  .w-30 {
    width: 30%;
  }

  .w-49 {
    width: 49%;
  }

  .w-35 {
    width:35%;
  }

  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }
}

.info-car-wrapper-completeness {
  display: flex;
  max-height: 900px;

  .info-car-wrapper__columns {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    gap: 2px;
    column-gap: 50px;

    &-column {
      margin-top: 16px;
    }

    &-title {
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 8px;
    }

    .info-car__field {
      max-width: 300px;

      .name {
        margin-left: 5px;
      }
    }
  }
}

.car-tabs-info-wrapper {
  &__btn-tab {
    display: flex;
    position: sticky;
    z-index: 9;
    gap: 12px;
    font-size: 14px;
    font-weight: 700;
    width: 100%;
    padding: 8px 0;
    margin: 24px 0;
    color: #8A92A1;
    cursor: pointer;
    background-color: #f4f6fb;

    &-selected {
      color: black;
      border-bottom: 2px solid #2D78E9;
      padding-bottom: 10px;
    }
  }

  .top-with-timer {
    top: 94px;
  }

  .top-without-timer {
    top: 50px;
  }
}

.spec-info {
  margin: 25px 0 100px 0;

  .section {
    margin-top: 16px;
    background-color: #FFFFFF;
    border: 1px solid #DEE6F5;
    border-radius: 8px;
    padding: 12px;
  }

  .title {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }

  .info-car-sec {
    margin-left: 20px;
  }

  .info {
    margin-left: unset !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #283445;
  }

  .info-car-thirds {
    width: 49.5%;
  }

  .car-photos__group {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;

    .car-sales__car-image {
      width: 360px;
      height: 240px;
    }
  }
}

@media screen and (max-width: 780px) {
  .car-tabs-info-wrapper {
    &__btn-tab {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      background-color: #283445;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 700;
      width: 100%;
      padding: 12px 0;
      border-radius: 60px;
      border: none;
      margin: 24px 0;
    }
  }

  .auction {
    margin: 0 !important;
    padding: 0 !important;
    border-top: none !important;

    .right-side {
      margin-left: 0;
    }

    .field {
      gap: 10px;
      margin-top: 0 !important;

      .name {
        width: 40%;
      }
    }
  }

  .spec-info {
    .section {
      .sale-section {
        border: none;
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          font-weight: 500;
          font-size: 14px;
        }
      }

      .title {
        margin: 0 0 12px 0;
      }

      .info-car-wrapper {

        &__columns {
          gap: 0;

          .info-car {
            width: 100% !important;
            border: none;
            padding: 0;
            gap: 0;

            &__field {
              padding-bottom: 4px;
            }
          }
        }
      }

      .pagination-wrapper {
        .result {
          font-size: 14px;
          font-weight: 600;
          width: 30%;
        }
      }
    }

    .car-photos {
      padding: 0;
      border: none;
      background-color: #F4F6FB;

      .car-sales__car-image {
        width: 164px;
        border-radius: 5px;
        height: auto;
      }
    }

    .car-damage {
      overflow-x: auto;

    &__wrapper {
      flex-direction: column;
      padding: 20px 0;

      .d-flex {
        width: 100% !important;

          .d-flex {
            flex-direction: column;
            width: inherit;
          }
        }
      }
    }
  }

  .equipmentWrapper {
    display: flex;
    flex-direction: column;
  }

  .info-car-wrapper-completeness {
    height: 100%;
    max-height: none;
  }

}

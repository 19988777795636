.single-auction-wrapper,
.edit-auction-wrapper {
  padding: 20px 30px 0 29px;

  .auction-info {
    padding: 15px 30px;
    background: #FFFFFF;
    border: 1px solid var(--main-color-light-blue);
    box-sizing: border-box;
    border-radius: 8px;

    &__field-wrapper {
      border-bottom: 1px solid #DEE6F5;
      padding: 10px 0;
    }

    &__field-wrapper:last-child {
      border-bottom: none;
    }
  }

  .auction-name {
    font-weight: 700;
    font-size: 22px;
    line-height: 135%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #283445;
  }

  .status {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 5px 12px 6px;
    border-radius: 6px;
    font-weight: 700;
    font-size: 12px;
    box-sizing: border-box;
    border: none;

    svg {
      border-left: 1px solid #2834451F;
      padding: 0 0 0 4px;
    }
  }

  .green {
    background: #6AC96E1A;
    color: #339337;

    svg {
      fill: #339337;
    }
  }

  .orange {
    background: rgba(254, 179, 92, 0.1);
    color: #F17B00;

    svg {
      fill: #F17B00;
    }
  }

  .red {
    background: rgba(240, 68, 97, 0.1);
    color: #F04461;

    svg {
      fill: #F04461;
    }
  }

  .row-container {
    border-bottom: 1px solid var(--main-color-light-blue);
    padding: 10px 0;

    &:last-child {
      border-bottom: 0;
    }
  }

  .row-name {
    width: 200px;
    font-weight: 600;
    color: #283445;
  }

  .reports-btn {
    border: 1px solid #c3c3c7;
    margin-left: 10px;
    background: #F4F6FB;
    width: 100%;
    max-width: 293px;
    padding: 11px 28px;
    margin-right: 15px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    outline: none;
    color: black;
  }

  .info-wrapper {
    padding: 30px;
    background: #FFFFFF;
    border: 1px solid var(--main-color-light-blue);
    box-sizing: border-box;
    border-radius: 8px;
  }

  .name {
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #283445;
  }

  .table-bordered > :not(caption) > * > * {
    overflow: hidden;
  }

  .table {
    .btn {
      min-width: 136px;
    }

    &__file-blue-text {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #2D78E9;
      margin-right: 5px;
      text-decoration: underline !important;
    }

    &__trades-history {
      font-weight: 700;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 4px;
      line-height: 20px;
      color: #2D78E9;
      cursor: pointer;
    }

    .edit-icon {
      padding: 4px;
      border-radius: 50%;
      cursor: pointer;
    }

    .edit-icon:hover {
      background-color: #3C83ED1A;
    }
  }
}

.buy-page-wrapper {
  padding: 20px 0;
}

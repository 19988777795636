.all-info-wrapper {
  padding: 20px 30px;
  background: #ffffff;
  border: 1px solid #dee6f5;
  border-radius: 8px;

  .title {
    font-weight: 700;
    font-size: 22px;
    line-height: 135%;
    color: #283445;
  }
}

.info-field-wrapper {
  margin-top: 20px;

  &_top {
    margin: 0 1.25rem 1rem 0;
  }

  .label {
    margin-right: auto;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    color: #283445;
  }

  .info-field,
  .radio-field,
  .upload-document {
    width: 100%;
    max-width: 540px;
  }

  .upload-document {
    border-width: 2px;
  }

  .file-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    color: #283445;
  }

  .radio-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    color: #283445;
  }

  .radio-field__container {
    display: flex;
    gap: 15px;
  }

  .text-margin {
    margin-bottom: 24px;
  }

  .radio-field .radio-conatiner:last-child {
    margin-bottom: 0;
  }

  .document-field__select {
    display: grid !important;
    grid-template-columns: 30% 70% !important;
    margin-top: 20px;
  }

  .document-field__select .react-select {
    max-width: 540px;
  }

  textarea {
    resize: none;
    height: 100px;
  }
}

.img-upload {
  width: 24%;
  padding: 20px;
  border: 1px solid #dee6f5;
  border-radius: 8px;

  .img-photo {
    width: 100%;
    aspect-ratio: 16/9;
  }

  .img-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    color: #283445;
  }

  .name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
  }

  .size {
    margin-left: auto;
    white-space: nowrap;
    margin-right: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #9ca3b0;
  }

  .loaded-file {
    padding: 10px 13px;
    background: #f4f6fb;
    border: 1px solid rgba(156, 163, 176, 0.1);
    border-radius: 6px;
  }

  .btn-primary {
    height: 48px;
    text-align: center;
    line-height: 26px;
    cursor: pointer;
  }
}

.dashed-border {
  cursor: pointer;
  background: #ffffff;
  border: 1px dashed #cad1dc;
  box-sizing: border-box;
  justify-content: center;
  padding: 10px;
}

.upload-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  width: 168px;
  text-align: center;
  color: #9ca3b0;
}

.upload-plus {
  font-weight: 300;
  font-size: 64px;
  line-height: 75px;
  text-align: center;
}

.loaded-document {
  width: 100%;
  max-width: 540px;

  .document {
    padding: 10px 13px;
    background: #f4f6fb;
    border: 1px solid rgba(156, 163, 176, 0.1);
    border-radius: 6px;
    margin-bottom: 10px;
  }

  .name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
  }

  .size {
    margin-left: auto;
    white-space: nowrap;
    margin-right: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #9ca3b0;
  }
}

.button {

  .grey {
    background: #f4f6fb;
    border: 2px solid #dee6f5;
  }
}

.no_photo {
  border-color: red;
}

.document {
  display: grid;
  grid-template-columns: 30% 70%;

  .loaded-file {
    width: 540px;
    padding: 10px 13px;
    background: #f4f6fb;
    border: 1px solid rgba(156, 163, 176, 0.1);
    border-radius: 6px;
    flex-shrink: 0;
  }

  .error {
    border-color: red;
  }

  .name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
  }

  .size {
    margin-left: auto;
    white-space: nowrap;
    margin-right: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #9ca3b0;
  }
}

.document-field__upload-input {
  height: 0;
  width: 0;
  line-height: 0;
  position: absolute;
  left: -9999px;
}

.car__modal-confirm .modal-content {
  width: 360px;
}

.add-car-suggestions {
  .suggestions {
    width: 100%;
  }

  .suggestions__list-wrap {
    margin-left: -21px;
    margin-right: -21px;
  }

  .suggestions__list {
    top: 12px;
  }
}

.btn-light {
  border: 2px solid #DEE6F5 !important;
  border-radius: 8px !important;
  padding: 10px 28px !important;
}

@media screen and (max-width: 780px) {
  .document {
    grid-template-columns: 45% 55%;
  }

  .all-info-wrapper {
    padding: 4vw;
    width: 96%;
    margin: auto;

    .rs-panel-body {

      .upload-document,
      .loaded-file,
      .info-field {
        flex-direction: row;
        justify-content: space-between;
        min-width: unset;
        width: 100%;
        gap: 4vw;

        .name, .size {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 0;
        }

        .name {
          width: 40vw;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .info-field {
        width: 100%;
      }
    }
  }

  .info-field-wrapper {
    word-break: break-word;

    &_top {
      margin-right: 0;
    }
    .radio-field {
      width: 45%;
      justify-content: space-evenly;
    }

    .radio-title {
      width: 55%;
    }

    label,
    .input-wrapper {
      gap: 2vw;
      flex-direction: column;
      align-items: start !important;
    }

    .document-field__select {
      grid-template-columns: 100% !important;
    }

      .select-label,
      .select-field-wrapper {
      flex-direction: column;
      align-items: flex-start !important;
      gap: 2vw;
    }

    .select-field-wrapper {
      padding-right: unset;
    }

    .text-margin {
      margin-bottom: 0;
    }
  }

  .rs-panel .rs-panel-body {
    grid-template-columns: unset !important;
  }

  .img-upload {
    width: 100% !important;

    .img-photo {
      aspect-ratio: unset !important;
    }
  }

  .mob-document-wrapper {
    width: 100%;
    grid-template-columns: 100% !important;
  }

  .mob-document-wrapper .upload-document {
    min-width: unset;
    width: 100%;
  }

  .mob-padding {
    padding: 4vw;
  }
}

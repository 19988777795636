.reports-wrapper {

  &_padding {
    padding: 47px 30px;
  }

  .title {
    font-weight: 700;
    font-size: 22px;
    line-height: 135%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #283445;
  }

  .brand-name {
    margin-left: 6px;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  .car-status {
    align-items: center;
    padding: 5px 12px 6px;
    border-radius: 40px;
    font-weight: 700;
    font-size: 12px;
    box-sizing: border-box;
  }

  .green {
    border: 1px solid #6AC96E;
    background: rgba(106, 201, 110, 0.1);
    color: #6AC96E;
  }

  .orange {
    border: 1px solid #FEB35C;
    background: rgba(254, 179, 92, 0.1);
    color: #FEB35C;
  }

  .buttons {
    margin-left: 40px;

    .waiting-btn,
    .warning-btn {
      padding: 5px 10px;
      border-radius: 8px;
      border: none;
      outline: none;
      font-weight: 700;
      font-size: 12px;
      line-height: 150%;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #ffffff;
    }

    .waiting-btn {
      width: 148px;
      background: #3c83ed;
    }

    .warning-btn {
      width: 181px;
      margin-left: 10px;
      background: #feb35c;
    }
  }

  .checked-from {
    margin-left: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #283445;
  }

  .checked {
    .btn-primary {
      margin-left: 40px !important;
    }
  }

  .car-info {
    gap: 20px;
    margin-top: 20px;
    padding: 20px 30px;
    background: #ffffff;
    border: 1px solid #dee6f5;
    border-radius: 8px;

    .key {
      margin-right: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #9ca3b0;
    }

    .value {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #283445;
    }

    & > div {
      display: flex;
      flex-direction: column;
      align-self: baseline;
      width: 25%;
    }
  }

  .accordion-button {
    background: #fff;
  }

  .accordion-wrapper {
    margin-top: 25px;

    .accordion-item {
      margin-top: 10px;
      border: 1px solid #dee6f5;
      border-radius: 8px;

      .accordion-header {
        border-radius: inherit;
      }
    }

    .accordion-head {
      margin-left: 12px;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #283445;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .accordion-item:first-of-type .accordion-button {
    border-radius: 8px 8px 0 0;

    &.collapsed {
      border-radius: inherit;
    }
  }
}

.modal-actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px
}

.select-light {
  padding: 9px 20px 11px;
  color: #9CA3B0;
  width: 100%;
  font-size: 14px;
  line-height: 150%;
  background: #F4F6FB;
  border: 1px solid rgba(156, 163, 176, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  margin-left: auto;
  margin-right: auto;

  &:disabled {
    opacity: 0.7;
  }
}


@media screen and (max-width: 780px) {
  .reports-wrapper {

    .mob-direction_column {
      gap: 4vw;
      width: 100% !important;

      .btn-primary {
        width: 100%;
        margin-left: 0 !important;
      }
    }

    .car-info {
      flex-direction: column;
      gap: 2vw;

      div {
        width: 100%;
        gap: inherit;

        .d-flex {
          flex-direction: column;
          align-items: start !important;
        }
      }
    }

    .actions {
      width: 100% !important;

      .action,
      .dropdown {
        width: inherit !important;
      }
    }
  }

  .mob-title-wrapper {
    flex-wrap: wrap;

    .title {
      width: 100%;
    }

    .car-status {
      border-radius: 20px;
      width: 50%;
      text-align: center;
    }
  }

}

.car-checked-icon {
  width: 20px;
  height: 20px;
}

@media screen and (min-width: 780px) and (max-width: 1200px) {
  .car-info .mob-direction_column .swiper-slider {
      width: 50%;
    }

  .car-info .mob-direction_column .info {
      width: 50%;
    }

  .mob-car_title-wrapper .car-status {
    margin-right: 10px;
  }
}

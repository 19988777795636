.auction-lots {

  .text-info {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 8px;
    background-color: #DEE6F5;
    border: 1px solid #DEE6F5;
    border-radius: 6px;
    color: #2D78E9 !important;
    font-size: 12px;
    font-weight: 500;
  }

  .lots-form-wrapper {
    border-bottom: 1px solid #DEE6F5;
    border-top: 1px solid #DEE6F5;
    padding: 16px 0;
  }

  .input-lot-form {
    max-width: 320px;
  }

  .input-name {
    position: absolute;
    margin-top: -25px;
    color: #656C7B;
    font-size: 12px;
    font-weight: 500;
  }

  &__input-commission {
    width: 80px;
    text-align: right;
    padding-right: 32px;

    &::placeholder {
      color: #283445;
    }
  }
  &__input-minimal-commission {
    width: 140px;
    text-align: right;
    padding-right: 32px;

    &::placeholder {
      color: #283445;
    }
  }

  &__placeholder-symbol {
    font-size: 16px;
    position: absolute;
    top: 10px;
    right: 12px;
    color: #656C7B;
    font-weight: 500;
  }

  .btn {
    padding: 6px 12px !important;
  }

  .lot-form-checked {
    border: 2px solid #8A92A2;
    border-radius: 4px;
  }

  .btn-add-car {
    display: flex;
    align-items: center;
    gap: 2px;
    padding: 6px 8px;
    background: #F4F6FB;
    border-radius: 6px;
    color: #2D78E9;
    border: 1px solid #F4F6FB;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
  }

  .lot-wrapper-card {
    position: relative;
    background: #FFFFFF;
    border: 1px solid #DEE6F5;
    box-sizing: border-box;
    border-radius: 8px;

    .info-wrapper__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px 12px;
    }

    .info-wrapper__header-edit {
      display: flex;
      gap: 16px;
      align-items: center;
    }

    .info-wrapper__header-edit-btn {
      display: flex;
      align-items: center;
      padding: 6px 8px;
      gap: 2px;
      background: #FAFBFF;
      border-radius: 6px;
      border: 1px solid #FAFBFF;
      color: #2D78E9;
      font-weight: 700;
    }

    .lot-wrapper-card__body {
      padding: 16px;
    }

    .head-name {
      font-weight: 600;
      font-size: 12px;
      color: #656C7B;
    }

    .row-text {
      font-weight: 500;
      font-size: 16px;
      color: #283445;
    }
  }
}

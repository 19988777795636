.suggestions {

    &__list-wrap {
        position: relative;
    }

    &__list {
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        max-height: 400px;
        z-index: 10;
        background: #FFF;
        border: 1px solid #F4F6FB;
        box-shadow: 0 24px 24px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        overflow-y: auto;
        color: #000;
    }

    &__list-item {
        cursor: pointer;
        padding: 7px 12px;
    }

    &__list-item_hover {
        background: #F4F6FB;
    }
}

.select-wrapper {

  .select-label {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    color: #283445;
  }

  .select-field-wrapper {
    width: 100%;
    padding-right: 20px;
    background: #f4f6fb;
    border: 1px solid rgba(156, 163, 176, 0.1);
    border-radius: 4px;
  }

  .select-field {
    width: 100%;
    min-width: 184px;
    padding: 9px 20px 11px 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #9ca3b0;
    border: 0;
    background: transparent;
  }

  .error {
    border-color: #f04461;
  }

  .success {
    border-color: #71d875;
  }

  .select-notice {
    display: none;
  }

  .error__show,
  .success__show {
    display: block;
  }
}

.create-car-sale-wrapper {
  padding: 40px 30px;

  .title {
    font-weight: 700;
    font-size: 22px;
    line-height: 135%;
    color: #283445;
  }

  .rs-steps-item {
    cursor: pointer;
  }

  .rs-steps-item-active {
    position: relative;

    .rs-steps-item-description {
      font-weight: 700;
      font-size: 12px;
      line-height: 140%;
      color: #000000;

      position: absolute;
      left: -40px;
    }

    .rs-steps-item-title {
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      color: #3c83ed;
    }
  }

  .rs-steps-item-status-wait,
  .rs-steps-item-status-finish {
    .rs-steps-item-description {
      font-weight: 700;
      font-size: 12px;
      line-height: 140%;
      color: #283445 !important;
    }

    .rs-steps-item-title {
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      opacity: 0.5;
      color: #3c83ed;
    }
  }

  .rs-steps-item-icon-wrapper {
    position: absolute;
    top: 5px;
    width: 10px;
    height: 10px;
  }

  .rs-steps-item-icon-wrapper > .rs-steps-item-icon {
    display: none;
  }
}

@media screen and (max-width: 780px) {

  hr {
    margin: 5px;
  }

  .create-car-sale-wrapper {
    padding: 0;

    .steps {
      padding: 20px 30px;

      .steps-container {
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        .step-item {
          width: 50%;
        }

        .radio-field {
          width: 50%;
        }

        .document-field__label {
          padding: 10px;
        }
      }
    }

    .title {
      padding: 10px 20px;
      margin-bottom: 0 !important;
    }

    .mob-button-wrapper {
      width: 80%;
      margin: auto;
      padding-bottom: 4vw;
    }
  }
}

.technical-check-wrapper {
  &__columns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;

    &-item {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &-title {
        font-size: 14px;
        font-weight: 700;
      }

      &-field {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 24px;

        .defect-short {
          font-size: 12px;
        }

        &-name {
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;

          &-link {
            cursor: pointer;
            color: #F17B00;
            text-decoration: underline;
          }
        }
      }
    }

    .green-text {
      display: flex;
      align-items: center;
      padding: 4px;
      border-radius: 6px;
      font-weight: 700;
      gap: 4px;
      background-color: #6AC96E1A;
      color: #339337;

      svg {
        fill: #339337;
      }
    }

    .alert-icon {
      background-color: #FF8F0C12;
      padding: 4px;
      border-radius: 6px;
    }
  }

}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.centered {
  height: max-content;
}
.mb-mobile {
  margin-bottom: 140px;
}

.mobile-tabs-modal {
  .modal-dialog {
    width: 70%;
  }

  .modal-content {
    padding: 0;
  }
  .mobile-tabs-modal-wrapper {
    &__header {
      border-bottom: 1px solid #DEE6F5;
      padding: 16px;
      font-weight: 700;
      line-height: 22px;
    }

    &__item:hover {
      background-color: #f4f6fb;
    }

    &__item {
      padding: 16px;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      text-align: left;
      display: flex;
      justify-content: space-between;

      svg {
        fill: #2D78E9;
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .technical-check-wrapper {
    &__columns {
      display: flex;
      flex-direction: column;
    }
  }
}



.upload-history-wrapper {

  .dropdown-menu {
    border: 1px solid #DEE6F5;
    border-radius: 8px;
    box-sizing: border-box;
  }

  .dropdown-item {
    width: auto;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 16px 12px;
    height: 44px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 14px;
    color: #283445;
    margin: 0 5px;
    border-radius: 8px;
  }

  .table__file-name {
    word-break: break-word;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #283445;
  }

  .table__file-extra-text {
    color: #656C7B;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }

  .table__file-id {
    color: #656C7B;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  td {
    height: 64px;
    padding: 12px !important;
  }

  .border {
    border: 1px solid #DEE6F5;
    border-bottom: 0;
  }

  tr:last-child {
    border-bottom: hidden;
  }

  .status-text {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    padding: 6px 7px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    border-radius: 6px;
  }

  .import_task_in_queue {
    background: rgba(60, 131, 237, 0.1);
    color: #2D78E9;
  }

  .import_task_in_progress {
    background: rgba(254, 179, 92, 0.1);
    color: #FEB35C;
  }

  .import_task_finished {
    background: rgba(106, 201, 110, 0.1);
    color: #339337;
  }

  .import_task_error {
    background: rgba(240, 68, 97, 0.1);
    color: #F04461;
  }

  .error_link {
    font-weight: 700;
    color: #283445;
    gap: 3px;
  }

  .table-text-secondary {
    font-size: 12px;
    color: #9CA3B0 !important;
  }

  .table-data-loading {
    width: 164px;
  }

  .table-data-loading-progress {
    width: 100%;
    height: 8px;
    border-radius: 8px;
    background-color: #EDF1FA;
    display: flex;
    overflow: hidden;
    margin-bottom: 5px;

    span {
      height: 100%;
      background-color: #2D78E9;
    }
  }
}

.trades-history-modal-wrapper .modal-dialog {
  display: flex;
  justify-content: center;
}

.trades-history-modal-wrapper .modal-content {
  width: 620px;
  background-color: #F4F5FB !important;
  padding: 24px 24px 0 24px;

  .steps-modal-text-left {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #283445;
  }

  .trades-history-modal-icon-wrap {
    width: 22px;
    height: 100%;
    display: inline-block;
  }

  .input-group {
    width: unset;
    flex-shrink: 0;

    .input {
      width: 160px;
      background-color: #FAFBFF;
    }

    .icon {
      margin: 15px 0 15px -37px;

      span {
        margin-left: -25px;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #283445;
      }
    }

    .decrement,
    .increment {
      border: none;
      padding: 10px 11px;
      background: #283445;
      outline: none;
      color: #fff;
    }

    .decrement {
      border-radius: 8px 0 0 8px !important;
    }

    .increment {
      border-radius: 0 8px 8px 0 !important;
    }
  }

  .steps-modal-wrapper-table {
    overflow-y: auto;
    margin-top: 24px;
    background-color: #FFFFFF;
    border: 1px solid #DEE6F5;
    border-radius: 8px;
    max-height: 400px;
  }

  .trades-history-modal__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    padding: 14px;
    margin: 10px -24px 0 -24px;
    border-top: 1px solid #DEE6F5;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    .pagination-wrapper {
      margin-top: 0;
    }
  }

  .steps-modal-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12px;
    border-bottom: 1px solid #DEE6F5;

    &:last-child {
      border-bottom: 0;
    }

    &__bet {
      display: flex;
      gap: 10px;
      min-width: 142px;
    }

    &__my-bet {
      background-color: #3C83ED1A;
      color: #2D78E9;
      font-weight: 700;
      font-size: 12px;
      padding: 6px;
      border-radius: 6px;
    }

    &__my-date {
      display: flex;
      gap: 20px;

      .day {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #656C7B;
      }

      .time {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #283445;
      }
    }
  }
}

.trades-history-modal-wrapper_no-content .modal-content {
  padding-bottom: 24px;
}

@media screen and (max-width: 780px) {
  .trades-history-modal-wrapper {
    .modal-dialog {
      margin: 0;
      height: 100%;
    }

    .modal-content {
      width: 100%;
      padding: 24px 16px 73px 16px;


      .steps-modal-item {
        &__my-date {
          flex-direction: column;
          gap: 0;
          width: 75px;
          text-align: end;
        }
      }

      .trades-history-modal__actions {
        gap: 5px;
        margin-right: 0;
        position: fixed;
        bottom: 0;

        .input-group {
          width: 50%;
          flex-wrap: nowrap;

          .input {
            width: calc(100% - 60px);
          }
        }

        .btn {
          width: 50%;
        }
      }
    }
  }
}

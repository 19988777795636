.filters-modal-wrapper {
  .modal-dialog {
    margin: 0;
  }

  .modal-content {
    background-color: #f4f5fb!important;
    padding: 0;
  }

  &__header {
    display: flex;
    margin: 26px 16px 0 16px;
    justify-content: space-between;
  }

  &__header-text {
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
  }

  .btn-clear {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #2D78E9;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }

  .filters-wrapper {
    margin: 16px;
    border: 1px solid #DEE6F5;
    border-radius: 8px;
    background-color: white;
    padding: 16px;

    .filter {
      display: grid;
      gap: 6px;
      width: 100%;

      .filter-name {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;

      }
    }
  }

  &__footer {
    padding: 16px 16px 8px 16px;
    margin: 0 -9px;
    box-shadow: 0px -8px 10px 0px rgba(40, 52, 69, 0.08);

    .btn {
      width: 100%;
    }
  }
}

@media screen and (max-width: 780px) {
  .filters-modal-wrapper {
    .filters-wrapper {
      .filter {
        display: grid;
        gap: 6px;
        width: 100%;
        padding: 0;
        margin-top: 0;
        border: none;
      }
    }
  }
}

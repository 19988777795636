.specification-wrapper {
  .info-car-wrapper {
    &__columns {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      gap: 50px;

      .info-car {
        display: flex;
        flex-direction: column;
        gap: 6px;
        box-sizing: border-box;
        border-radius: 8px;

        &__field {
          display: flex;
          gap: 10px;
          padding-bottom: 6px;

          .name {
            width: 140px;
          }
        }
      }
    }

    &__comment {
      border-top: 1px solid #DEE6F5;
      padding-top: 10px;
      margin-top: 12px;
    }
  }
}

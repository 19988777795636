$custom_grey: #283445;

.length,
.comment,
.confirm-btn,
.modal-small-text,
.accordion-small-text,
.diagnostic-wrapper .percent,
.table-wrapper thead th,
.comments-wrapper .comments-length {
  color: $custom_grey;
}

.accordion-button:after {
  display: none;
}

.report-img {
  width: 35%;
}

.wheel-image {
  width: 2vw;
}

.accordion-images-preview {
  display: flex;
  gap: 14px;
}

.confirm-btn {
  padding: 5px 10px;
  border: 1px solid #f4f6fb;
  border-radius: 5px;
  background: white;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
}

.confirm-btn.blue-button {
  background: #3c83ed;
  border: 1px solid #3c83ed;
  color: #ffffff;
}

.length {
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  text-align: right;
}

.buttons-wrapper {
  width: 37%;
}

.table-wrapper {
  padding: 15px 60px 0 24px;
  background: #f4f6fb;
  border: 1px solid var(--main-color-light-blue);
  border-radius: 8px;

  .table > :not(caption) > * > * {
    border-bottom-width: 0;
  }

  thead {
    background: var(--main-color-light-blue);
    border: 1px solid var(--main-color-light-blue);
    border-radius: 8px 0 0 8px;

    th {
      border-right: 1px solid var(--main-color-light-blue);
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
    }
  }

  .add-photo-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    background: #3c83ed;
    border-radius: 8px;
    padding: 10px;
    color: #fff;
    font-weight: 700;
    border: none;
    outline: none;
  }

  .description {
    width: 340px;
  }

  input[type='radio'] {
    margin-right: 20px;
  }
}

.accordion-body {
  .actions {
    display: flex;
    justify-content: flex-end;

    .btn-primary {
      margin-left: unset !important;
    }
  }
}

.comments-wrapper {
  .title {
    font-size: 16px;
  }

  .comment-field {
    width: 100%;
    background: #ffffff;
    border: 1px solid var(--main-color-light-blue);
    border-radius: 6px;
    padding: 1rem 1rem 0 1rem;
    resize: none;
  }

  .comments-length {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-content {
  background-color: #fff !important;
}

.image-wrapper {
  width: 533px !important;
  padding: 10px 23px;
  background: #f4f6fb;
  border: 1px solid rgba(156, 163, 176, 0.1);
  border-radius: 6px;
}

.selected-images {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.company-name {
  margin-left: 12px;

  .name {
    font-weight: 700;
    font-size: 13px;
    line-height: 150%;
    color: #11142d;
  }

  .company {
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.008em;
    color: #9ca3b0;
  }
}

.comment {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}

.green-text {
  color: #6ac96e;
}

.btn.btn-empty {
  text-decoration: none;
  padding: 0 0 0 1px;
}

.defect-short {
  display: flex;
  align-items: baseline;
}

.w-40 {
  width: 40% !important;
}
.w-20 {
  width: 20% !important;
}
.mr-3 {
  margin-right: 3em;
}

.col-name {
  display: flex;
  align-items: center;
}

.selection-input-4.col-name-input {
  margin-right: 10px !important;
}

.accordion-small-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  display: flex;
}

.accordion-defects-group {
  display: flex;
  gap: 12px;

  .btn-secondary {
    padding: 6px 10px;
  }
}

.accordion-body .actions {
  gap: 5px;
}

.wheels-wrapper,
.reports-wrapper {
  .btn-disabled {
    color: rgba(48, 86, 156, 0.5);
    border: 2px solid rgba(222, 230, 245, 0.5);
    user-select: none;
  }

  .btn.btn-defect {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    padding-top: 5px;
    padding-bottom:5px;
  }

  .btn-defect-icon {
    -webkit-transition: background-image 0.2s ease-in-out;
    transition: background-image 0.2s ease-in-out;
    background-image: url("../../../../public/img/icons/add-report-icon.svg");
    width: 12px;
    height: 18px;
    display: inline-block;
    background-repeat: no-repeat;
  }

  .btn-defect:hover .btn-defect-icon {
    background-image: url("../../../../public/img/icons/add-report-icon-hover.svg");
  }

  tbody tr:first-child .input,
  tbody tr:first-child .react-select {
    background-color: white;
  }
}


.defect-modal-wrapper {
  &__header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;

    &-text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      display: flex;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    border-top: 1px solid #DEE6F5;

    &-field {
      display: flex;
      align-items: center;

      &-name {
        width: 25%;
      }

      &-value {
        display: flex;
        align-items: center;
        width: 54%;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: end;
    gap: 10px;
    padding-top: 12px;
    border-top: 1px solid #DEE6F5;
  }
}

@media screen and (max-width: 780px) {
  .defect-modal-wrapper {
    .modal-dialog {
      width: auto;
      height: 100%;
      align-items: center;
      display: flex;
    }

    .modal-content {
      padding: 16px;
    }

    &__header {
      flex-direction: column;
    }

    &__form {
      padding: 16px 0;

      &-field {
        flex-direction: column;
        gap: 6px;

        &-name {
          width: 100%;
          text-align: start;
        }

        &-value {
          width: 100%;

          .image-wrapper {
            width: 100% !important;
          }
        }
      }
    }

    .report-img {
      width: 100% !important;
      margin-bottom: 12px;
    }

    &__buttons {
      flex-direction: column;
    }
  }
}

.modal-content {
  border: 1px solid var(--main-color-light-blue);
}

div.modal-dialog {
  width: 100%;
  max-width: 1170px;
}

.content-modal-car {
  .modal-content {
    border: 1px solid #DEE6F5;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #F4F5FB !important;
  }

  .input {
    width: 272px;
  }

  .input_small {
    width: 180px;
  }
}

.car-table {
  table {
    background: #fff;
    border-radius: 8px !important;
  }

  .btn-primary {
    float: right;
    margin: 15px 25px;
  }

  tr {
    border: 1px solid #DEE6F5 !important;
    border-radius: 8px 8px 0 0 !important;
  }
}

.header-car {
  button {
    margin-left: 40px;
  }
}

.title,
.title-filter {
  font-weight: bold;
  font-size: 22px;
  line-height: 135%;
  color: #283445;
}

.title-filter {
  padding: 20px 30px;
}

@media screen and (max-width: 780px) {
  .mob-direction {

    &_column {
      flex-direction: column !important;
    }

    &_row {
      flex-direction: row !important;
    }
  }

  .mob-dropdown {
    width: 80%;
    justify-content: end !important;

    a {
      border-radius: 50% !important;
      width: 40px;
      height: 40px;
      padding: unset !important;
      background: #0d6efd url("../../../../public/img/icons/plus-sign.svg") no-repeat 50% 50% !important;
      background-size: 50% !important;
    }

    a .desktop-caption {
      display: none;
    }

    .dropdown {
      display: none;
    }
  }

  .modal-dialog-centered {
    width: 90% !important;
    min-width: unset;
    margin: auto !important;
    padding: 4vw 0;
  }

  .content-modal-car {
    .modal {

      &-header {

        div {
          flex-direction: column;
          width: 80% !important;
        }

        button {
          padding: 0 !important;
          margin: 0 !important;
          left: 0 !important;
        }
      }

      &-body {
        min-height: 180px;
        height: unset;

        .form {
          min-height: 280px;
          height: unset;

          .d-flex {
            width: 100% !important;
          }

          .name {
            display: none;
          }

          .report-img {
            width: 50% !important;
          }

          .mob-image-wrapper div {
            width: 100% !important;
          }

          .w-100 .w-50 {
            width: 85% !important;
          }

          .accordion-tooltip {
            right: -15%;

            &__draggable {
              display: none;
            }
          }

          .mob-defect-selector {
            width: 100% !important;
            margin-top: 20px !important;

            .d-flex {
              width: 85% !important;
            }
          }

          .selected-images {
            width: 100% !important;

            .image-wrapper {
              width: 100% !important;
              gap: 2vw;

              div {
                white-space: nowrap;
              }
            }
          }
        }
      }

      &-footer {
        flex-direction: row;

        .buttons-wrapper {
          width: 100% !important;
        }

        button {
          width: 45%;
        }
      }

      &-content {
        padding: 2vw !important;
      }
    }
  }
}

.add-user-group-wrapper {
  padding: 20px 30px 0 29px;

  .input {
    max-width: 540px;
  }

  .react-select {
    max-width: 540px;
  }

  .title {
    font-weight: 700;
    font-size: 22px;
    line-height: 135%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #283445;
  }

  .name {
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #283445;
  }

  .info-wrapper {
    padding: 30px;
    background: #FFFFFF;
    border: 1px solid #DEE6F5;
    box-sizing: border-box;
    border-radius: 8px;
  }

  .tooltip-wrapper {
    width: 24px;
    height: 24px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F2F6FF;
    border: 1px solid #DEE6F5;
    border-radius: 50%;
  }
}

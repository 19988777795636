$bezier-curve: cubic-bezier(1,.01,.18,.9);
@mixin transition-settings-on-open {
  transition: font-size 0.3s $bezier-curve;
  font-size: 14px;
}
@mixin transition-settings-on-close {
  transition: font-size 0s $bezier-curve !important;
  font-size: 0 !important;

}

:root {
  --vars-left-menu-width: 292px;
}

.side-bar-wrapper {
  transition: width 0.2s ease-in-out;
  width: var(--vars-left-menu-width);
  min-height: 100vh;
  background: #fff;
  padding: 0 10px 20px;
  overflow-y: auto;
  box-sizing: border-box;

  .logo {
    width: 150px;
  }

  &_ru .logo {
    margin: 28px 0 0 23px;
  }

  &_kz .logo {
    height: 70px;
  }

  &_ru .items {
    margin-top: 36px;
  }

  &_kz .items {
    margin-top: 20px;
  }

  .title {
    @include transition-settings-on-open;
    font-weight: 500;
    line-height: 150%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #9ca3b0;
    margin-left: 1rem;
  }

  .item-wrapper {
    width: 100%;
    padding: 0 0 0 1rem;

    a {
      width: 100%;
      padding: 10px 0px;

      .item-name {
        @include transition-settings-on-open;
        font-weight: 700;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #283445;
      }
    }
  }

  .svg-wrapper {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 17px;
  }

  .selected {
    background: #283445;
    border-radius: 6px;

    .item-name {
      @include transition-settings-on-open;
      color: #fff !important;
    }

    svg {
      path {
        fill: #fff;
      }
    }
  }
}

.closed-side-bar {
  transition: width 0.2s ease-in-out;
  padding: 0 12px;
  --vars-left-menu-width: 66px;

  .mini-logo {
    width: 27px;
    height: 23px;
    margin-left: 9px;
  }

  .items {
    margin-top: 24px;

    .title {
      @include transition-settings-on-close;
    }
  }

  .item-wrapper {
    justify-content: center;
    padding: 0;

    a {
      width: unset;
    }
  }

  .item-name {
    @include transition-settings-on-close;
  }

  .svg-wrapper {
    margin-right: 0;
  }
}

.side-bar-wrapper_kz.closed-side-bar .items {
  margin-top: 16px;
}

@media screen and (max-width: 780px) {

  .side-bar-wrapper a .logo {
    display: none;
  }

  .side-bar-wrapper {
    width: 320px;
    position: fixed;
    left: 0;
    z-index: 1;
    top: 50px;
    min-height: calc(100% - 50px);
    bottom: 0;

    .items {
      margin-top: 20px;
    }
  }

  .closed-side-bar {
    visibility: hidden;
  }
}

.card-auction {
  background: #ffffff;
  border: 1px solid var(--main-color-light-blue);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px;

  &_fixed .card-auction__header-wrap {
    height: 44px;
  }

  &_fixed .card-auction__header {
    position: fixed;
    top: 50px;
    background-color: #FFF;
    z-index: 9;
    margin-left: -46px;
    width: calc(100% - var(--vars-left-menu-width));
    min-height: 44px;
    padding: 0 28px;
    border-top: 1px solid #DEE6F5;
    border-bottom: 1px solid #DEE6F5;
  }

  &__information {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .left-side {
    .auction-id {
      margin-right: 20px;
      font-size: 14px;
      font-weight: 700;
      line-height: 135%;
      color: #283445;

      a {
        color: #283445;
      }
    }

    .seller, .manager {
      margin: 0 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #283445;
    }

    .manager {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .lots-number {
      display: flex;
      align-items: center;
    }

    .date-from-to,
    .user {
      margin-right: 20px;
      align-items: center;

      img {
        margin-right: 8px;
      }
      .date,
      .name {
        margin-right: 20px;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #283445;
      }
    }
  }

  .timer {
    margin-left: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #283445;
  }

  .right-side {
    .price-wrapper {
      margin-right: 20px;
    }

    .bid-wrapper {
      margin-right: 20px;

      .bid {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #283445;
      }
      img {
        margin-right: 8px;
      }
    }

    .end-auction-wrapper {
      margin-right: 20px;

      img {
        margin-right: 8px;
      }

      .time {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #283445;
      }
    }

    .documents {
      img {
        margin-right: 8px;
      }

      .count {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #283445;
      }
    }
  }

  .favorite-img {
    cursor: pointer;
  }

  .lot-car-wrapper {
    padding: 0 10px 16px 10px;
    background: #f4f6fb;
    border: 1px solid var(--main-color-light-blue);
    box-sizing: border-box;
    border-radius: 8px;

    &_show-btn {
      padding-right: 220px;
    }
  }

  .actions {
    margin-top: 15px;

    .input-group {
      width: unset;

      .icon {
        margin: 15px 0 15px -37px;

        span {
          margin-left: -25px;
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          color: #283445;
        }
      }
    }

    .input {
      width: 142px;
    }

    .currency,
    .diagram-rate {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--main-color-light-blue);
      box-sizing: border-box;
      border-radius: 8px;
      padding: 10px;
      cursor: pointer;

      img {
        width: 10px;
      }
    }

    .currency,
    .diagram-rate {
      margin-right: 15px;
    }

    .starting-price {
      margin-left: 5px;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
      color: #283445;
    }

    .btn-primary {
      width: 200px;
      margin: 0;
      box-shadow: none;
    }

    .tooltip-wrapper {
      width: 14px;
      height: 14px;
      padding: 7px;
      background: rgba(156, 163, 176, 0.4);
      margin-left: 5px;

      img {
        height: 9px;
      }
    }

    .last-bet-wrapper {
      .tooltip-wrapper {
        padding: 5px;
      }
    }

    .btn__active {
      background-color: #339337;
      border-color: #339337;
    }

    .btn__bought-by-me {
      background-color: #8A92A1;
      border-color: #8A92A1;
    }

    .btn__bought-by-not-me {
      background-color: #DDE5F3;
      border-color: #DDE5F3;
      color: #8A92A1;
    }
  }

  .lots-actions {
    &-wrapper {
      display: grid;
      align-items: center;
      grid-template-areas: 'C D E . . A B H';
    }

    &-wrapper-horizontal {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
    }

    &-wrapper-two {
      display: grid;
      grid-template-areas:
            'C C D D E E'
            'G G G G G G'
            'A B . . . H';
      gap: 8px;
    }

    &-wrapper-three {
      display: grid;
      grid-template-areas:
            'C C D D G E'
            'A B . . . H';
      gap: 8px;
    }

    .current-bet {
      grid-area: A;
    }

    .el-step {
      grid-area: B;
    }

    .input-group {
      grid-area: C;
      .input {
        width: 180px;
      }
    }

    &__btns {
      display: flex;
      gap: 8px;
      justify-content: end;
      grid-area: E;
    }

    .auction__bet-btn {
      grid-area: D;
      width: auto;
    }

    .card-auction__lot-blitz {
      grid-area: G;
    }

    .favorite-icon {
      grid-area: H;
      text-align: end;
    }
  }

  .actions-mobile {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .current-bet {
      font-size: 14px;
    }

    .btn-primary {
      width: 100%;
    }

    &__btn-wrapper {
      display: flex;
      gap: 8px;

      .input-group {
        width: 50% !important;
      }

      .auction__bet-btn {
        width: 50%;
      }
    }
  }

  .watch-to-down-icon,
  .cart-icon {
    width: 48px;
    padding: 10px 12px;
    border: 2px solid var(--main-color-light-blue);
    border-radius: 8px;
  }

  .success-message {
    border: 1px solid #7ec720;
    color: #4b7417;
    background: white;
    padding: 7px 20px 9px;
    border-radius: 8px;
    position: absolute;
    top: -40px;
    right: 0;
  }

  .success-message,
  .error-message {
    z-index: 8;
  }

  .type-auctions {
    .type-of-auction {
      margin-right: 21px;
    }

    .auction-type-icon_down {
      transform: rotate(180deg);
    }
  }

  .icons-wrapper {
    .bid-up {
      margin-right: 10px;
    }
  }

  .decrement,
  .increment {
    border: none;
    padding: 10px 11px;
    background: #283445;
    outline: none;
    color: #fff;
    width: 30px;
  }

  .decrement {
    border-radius: 8px 0 0 8px !important; // есть ситуации, в которых бутстрап сбрасывает это значение
  }

  .increment {
    border-radius: 0 8px 8px 0 !important; // есть ситуации, в которых бутстрап сбрасывает это значение
  }

  .seconds-wrapper {
    img {
      margin-right: 11px;
    }

    .name {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #283445;
    }
  }

  .price {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #283445;
  }

  &__lot-blitz {
    color: #283445;
    background-color: #FFD058;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: none !important;
    gap: 10px;

    &:hover {
      opacity: 0.85;
    }

    &_single {
      margin-top: 8px;
      width: 100%;
    }
  }
}

.opening-hours {
  display: flex;
  align-items: center;
}

.opening-hours .wrap-indicator {
  position: relative;
  margin-right: 10px;
}

.opening-hours .indicator {
  position: relative;
  z-index: 10;
  width: 10px;
  height: 10px;
  background-color: #e31e24;
  border-radius: 50%;
}

.opening-hours .wrap-indicator.active .indicator {
  background-color: #63c561;
}

.opening-hours .wrap-indicator.active .pulsation {
  position: absolute;
  z-index: 5;
  top: -3px;
  left: -3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #63c561;
  opacity: 0.1;
  -webkit-animation: indicator-pulsation 2s infinite ease-in-out;
  animation: indicator-pulsation 2s infinite ease-in-out;
}

.green-background {
  background: rgba(106, 201, 110, 0.1) !important;
  border: 1px solid rgba(106, 201, 110, 0.3) !important;

  .watch-to-down-icon,
  .cart-icon {
    border: 2px solid rgba(106, 201, 110, 0.3) !important;
  }
}

.red-background {
  background: rgba(240, 68, 97, 0.1) !important;
  border: 1px solid rgba(240, 68, 97, 0.3) !important;

  .watch-to-down-icon,
  .cart-icon {
    border: 2px solid rgba(240, 68, 97, 0.3) !important;
  }
}

.tooltip.show {
  opacity: 1;
}

.tooltip-arrow {
  border-top-color: #fff !important;
}

@keyframes indicator-pulsation {
  0% {
    transform: scale(0.5);
  }

  75% {
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.disabled-auctions {
  background: #F4F6FB;
  opacity: 0.5;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
}

.minimal-price,
.last-bet {
  margin-left: 6px;
}

.line {
  border: none;
  background: var(--main-color-light-blue);
  height: 1px;
  margin: 0;
}

.cart-icon-down {
  border: 2px solid var(--main-color-light-blue);
  width: 48px;
  padding: 10px 12px;
  border-radius: 8px;
}

.input-value,
.input-value-sec {
  width: 110px;
  position: absolute;
  background: #fff;
  top: 11px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #283445;
}

.input-value {
  text-align: center;
  left: 40px;
}

.input-value-sec {
  left: 40px;
}

.x {
  opacity: 0;
  transform: translateY(-10px);
  transition: 0.3s;
}

.changed-timer {
  opacity: 1;
  transition: 0.3s;
  transform: translateY(0);
}

.current-timer {
  display: none;
}

.visible {
  opacity: 1;
  height: auto;
}

.hidden {
  opacity: 0;
  height: 0;
}

.y {
  transform: translateY(10px);
}

.auction {

  &__bet-btn {
    height: 44px;
  }
}

.steps-modal-wrapper {
  width: 510px;
  height: 509px;
  top: 20%;
  left: 35%;

  .sub-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #283445;
  }

  .success-message {
    border: 1px solid #7ec720;
    color: #4b7417;
    background: white;
    padding: 7px 20px 9px;
    border-radius: 8px;
    position: absolute;
    top: -40px;
    right: 0;
  }

  .decrement,
  .increment {
    border: none;
    padding: 10px 11px;
    background: #283445;
    outline: none;
    color: #fff;
  }

  .decrement {
    border-radius: 8px 0 0 8px;
  }

  .increment {
    border-radius: 0 8px 8px 0 !important;
  }

  .content-modal {
    z-index: 9999;
  }
}

.fixed-head-text {
  background-color: #fff;
  top: 22%;
}

.card-auction__lots-wrapper.horizontal {
  flex-direction: column;
}


.lot-card__point {
  position: absolute;
  z-index: 10;
  background-color: #E4AE5D;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  top: 4px;
  right: 4px;
}

.bid-up_active {
  z-index: 10;
}

.auto-bet-modal-wrapper .modal-content {
  background-color: #F4F6FB !important;
}

.auto-bet-modal-wrapper {
  height: auto;
  width: 510px;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  &__car-name {
    color: #656C7B;
    font-size: 12px;
  }

  &__text {
    text-align: start;
    font-size: 14px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 4px;
  }

  &__btns {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid #DEE6F5;

    .btn-delete {
      border: 2px solid #FE8DA0;
      border-radius: 8px;
      background-color: #FFFFFF;
      color: #F04461;
    }
  }
}

@media screen and (max-width: 780px) {
  .card-auction {

    &_fixed .card-auction__header-wrap {
      height: 116px;
    }

    &_fixed .card-auction__header {
      margin-left: 0;
      left: 0;
      width: 100%;
      height: 116px;
      padding: 12px 16px;
    }
  }

  .card-auction__lots-wrapper.tile {

    .auction__bet-btn {
      width: 100%;
    }
  }

  .auto-bet-modal-wrapper {
    .modal-dialog {
      margin: 8px;
    }
      .select-light {
        width: 80%;
      }
  }

  .steps-modal-wrapper {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .card-auction {
    .card-auction__information {
      display: grid;
      gap: 8px;
      grid-template-areas:
      'A F . G K'
      'B B B B B'
      'C C C C C'
      'D D D D E';
    }

    &__information-top-bar {
      gap: 2px !important;
      padding: 12px 0;
    }

    .auction-id {
      grid-area: A;
      display: flex;
      flex-shrink: 0;
    }

    .seller {
      grid-area: B;
    }

    .manager {
      grid-area: C;
    }

    .date-from-to {
      grid-area: D;

      .date {
        margin-right: 0 !important;
      }
    }

    .lots-number {
      grid-area: E;
      justify-content: end;
    }

    .type-auctions {
      grid-area: F;
      display: flex;
    }

    .radar {
      grid-area: G;
    }

    .seconds-wrapper {
      grid-area: K;
    }

    .left-side {
      .date-from-to {
        img {
          width: 14px;
          margin: 0 4px 0 0;
        }
      }

      .user {
        img {
          width: 14px;
        }
      }
    }

    .type-of-auction {
     width: 20px;
     margin-right: 4px !important;
    }

    .user, .name, .seller, .manager, .auction-id, .radar, .date-from-to {
      margin: 0 !important;
    }

    .seconds-wrapper img {
      margin-right: 4px;
    }
  }

  .lot-car-wrapper {
    width: 100%;
    border-bottom: none !important;

    &_show-btn {
      padding-right: 10px !important;
    }

    .actions {
      .input-group {
        width: 100%;
      }

      .input {
        width: calc(100% - 60px);
      }
    }
  }
}

.card-auction .radar,
.single-car-wrapper__lot-info-top .radar {
  margin-right: 20px;
  position: relative;
  display: flex;
  align-items: center;

  .shadow-gray {
    box-shadow: 0 0 1px 1px #5e5e5e;
  }

  .animation-red {
    background-color: red;
  }

  .shadow-red {
    box-shadow: 0 0 1px 1px red;
  }

  .animation-green {
    background-color: #63c561;
  }

  .shadow-green {
    box-shadow: 0 0 1px 1px #6ac96e;
  }

  .animation-yellow {
    background-color: yellow;
  }

  .shadow-yellow {
    background-color: #e0e632;
  }

  .animation-circle {
    margin-right: 6px;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    position: relative;
  }

  .animation-container {
    position: absolute;
    z-index: 5;
    top: -3px;
    left: -3px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    opacity: 0.1;
    -webkit-animation: indicator-pulsation 2s infinite ease-in-out;
    animation: indicator-pulsation 2s infinite ease-in-out;
  }

  @keyframes shadow {
    0% {
      transform: scale(0.5);
    }

    75% {
      transform: scale(1);
      opacity: 0.5;
    }

    100% {
      transform: scale(1);
      opacity: 0;
    }
  }

  .animation-gray {
    background-color: gray;
    animation: none;
  }
}

@media screen and (min-width: 780px) and (max-width: 1200px)  {
  .card-auction {
    .card-auction__information {
      flex-wrap: wrap;
    }

    .lot-cars__wrapper .horizontal {
      .car-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }
    }

    .lots-actions {
      &-wrapper-two {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.access-rights-wrapper {
  width: 840px;
}

.row-text {
  font-weight: 700;
  font-size: 16px;
  color: #283445;
}

.title-name {
  font-weight: 400;
  font-size: 22px;
  color: #8A92A2;
}

.message-name {
  font-weight: 500;
  font-size: 14px;
  color: #8A92A2;
}

.info-name {
  color: #656C7B;
  font-weight: 500;
  font-size: 12px;
}

.bold-text {
  font-weight: 700;
  font-size: 16px;
  color: #283445;
}

.access-users-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: 40px;

}

.access-rights-wrapper__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 8px 64px;
  background: #EDF1FA;
}

.access-users-wrapper__header {
  display: flex;
  align-items: start;
  height: 80px;
}

.access-users-wrapper__table {
  margin-top: 20px;
  width: 100%;
  border: 1px solid #DEE6F5;
  border-radius: 8px;

  a {
    text-decoration: underline !important;
  }
}

.access-users-wrapper__table-th {
  background: #EDF1FA;
  font-weight: 700;
  font-size: 14px;
  padding: 8px 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;
}

.access-users-wrapper__table-td {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #DEE6F5;
  padding: 6px;
  height: 48px;
  box-sizing: border-box;
}

.access-users-wrapper__table-td:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.access-users-wrapper__table_block a {
  color: #656C7B;
}

.hint {
  width: 100%;
  background: #EDF1FA;
  border-radius: 8px;
  text-align: center;
  padding: 48px 25px;
}

.link-user-or-group {
  color: #2D78E9;
  font-weight: 700;
  font-size: 14px;
  text-decoration-line: underline;
}

.message-wrapper {
  max-width: 367px;
  width: 100%;
  text-align: center;
}

.buy-wrapper {
  padding: 26px 30px;

  .arrow-down {
    margin-left: 20px;
  }

  &__name-title {
    display: none;
    color: #656C7B;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  .btn-action__approve {
    display: inline-flex;
    gap: 6px;
    align-items: center;
    margin-right: 6px;
  }

  .btn-action__approve, .btn-ghost {
    width: 136px;
  }

  .tabs-wrapper {
    display: flex;
    justify-content: space-between;

    .tabs {
      display: flex;
    }
  }

  .btn-filters {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border: 2px solid #DEE6F5;
    border-radius: 6px;
    padding: 5px 8px;
    background-color: #FFFFFF;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    width: 112px;

  }

  .filters-wrapper {
    padding-bottom: 24px;
    border-bottom: 1px solid #DEE6F5;

    .filter {
      padding: 20px;
      margin-top: 15px;
      background: white;
      border: 1px solid #DEE6F5;
      box-sizing: border-box;
      border-radius: 8px;
    }
  }

  .confirmed-bet {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
  }

  .input, .react-select {
    width: 320px;
  }
}

@media screen and (max-width: 780px) {
  .buy-wrapper {
    padding: 24px 16px;

    .title {
      margin-top: 0;
    }

    &__name-title {
      display: block;
    }

    .tabs-wrapper {
      padding: 0;
      gap: 6px;
      margin-top: 20px;

      .filters-count {
        top: -8px;
        left: 92px;
      }

      .tabs {
        gap: 4px;
        width: 100%;
        justify-content: end;
        flex-wrap: nowrap;
        margin-top: 0;

        .tab-btn {
          padding: 5px 10px !important;
          height: 32px;
          justify-content: space-between;
        }
      }
    }

    .react-bootstrap-table {
      th {
        width: 40vw;
      }
    }
  }
}

.last-bet-delete-modal-wrapper {
  .modal-dialog {
    display: flex;
    justify-content: center;
  }

  .modal-content {
    width: 480px;
  }

  .row-name {
    color: #656C7B;
    font-size: 12px;
    font-weight: 600;
  }

  .row-value {
    color: #283445;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }

  &__btns {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid #DEE6F5;
    gap: 10px;

    .btn-cancel {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      border: 2px solid #FE8DA0;
      border-radius: 8px;
      padding: 12px;
      color: #F04461;
      font-size: 14px;
    }

    .btn-close-modal {
      gap: 8px;
      border: 2px solid #DEE6F5;
      border-radius: 8px;
      padding: 12px;
      color: #283445;
      font-weight: 700;
      font-size: 14px;
    }
  }
}

.auction-trades-history-modal-wrapper .modal-dialog {
  display: flex;
  justify-content: center;
}

.auction-trades-history-modal-wrapper .modal-content {
  width: 1000px;
  background-color: #F4F5FB !important;

  .table-class-wrap .table {
    min-width: 400px;
  }

  .table-bet {
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;

  }

  .table__time {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;

  }

  .table__time-hour {
    color: #656C7B;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }

  .table-link {
    color: #2D78E9;
    font-weight: 700;
    font-size: 14px;
    text-decoration-line: underline !important;
  }

  .btn-delete-bet {
    display: flex;
    align-items: center;
    gap: 2px;
    background-color: #F4F6FB;
    border-radius: 6px;
    color: #F04461;
    padding: 8px;
    border: none;
  }

  .pagination-wrapper {
    justify-content: start;
  }
}
